import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { ToastSuccess, ToastError } from "../../common/Toast";

const initialState = { isLoading:false, isError:'', ticketsRes:null, ticketsInfo:null, ticketsList:null, updateTicketsRes:null };

const ticketSlice = createSlice({
    name:'ticketsSlice',
    initialState,
    reducers: {
        apiCallRequested(state){
            state.isLoading= true;
            state.isError='';
            state.ticketsRes = null;
            state.updateTicketsRes = null;
        },
        apiCallSuccess(state,action){
            state.isLoading = false;
            state.ticketsRes=action.payload;
            state.ticketsInfo = null;
        },
        apiCallFailed(state,action){
            state.isLoading=false;
            state.isError=action.payload;
        },
        getTicketsListsSuccess(state,action){
            state.isLoading= false;
            state.ticketsList = action.payload;
            state.ticketsInfo = null;
        },
        getTicketsInfoSuccess(state,action){
            state.isLoading = false;
            state.ticketsInfo=action.payload;
        },
        updateTicketsInfo(state,action){
            state.isLoading=false;
            state.updateTicketsRes=action.payload;
            state.ticketsInfo = null;
        }


    }
});


export const getTicketsApi = () => {
    return async (dispatch,getState) => {
        dispatch(ticketsActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "GET",
					url: `me/tickets/list`,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                if(res?.data?.error === false){
                    const { data } = res;
                    dispatch(ticketsActions.getTicketsListsSuccess(data?.data))
                }else{
                    throw 'something went wrong '
                }
        } catch (error) {
            dispatch(ticketsActions.apiCallFailed(error));
            console.log(error)
        }
    }
};



export const getTicketsById = (id) => {
    return async (dispatch,getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(ticketsActions.apiCallRequested());
        try {
            const res = await axios({
					method: "POST",
					url: `/me/tickets/get`,
                    data:{
                        request_id:id
                    },
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
            
           
            if(res?.data?.error===false){
                const { data } = res;
                dispatch(ticketsActions.getTicketsInfoSuccess(data?.data))
            }else{
                throw 'something went wrong '
            }
        } catch (error) {
            dispatch(ticketsActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const addTicketsApi = (data)=> {
    return async (dispatch,getState)=>{
        const token = getState()?.LoginRes?.authResToken;
        dispatch(ticketsActions.apiCallRequested());

        try{
            const res = await axios({
                method:'POST',
                url:`/me/tickets/add`,
                data,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });
        
            const {error , message } = res?.data;
            if(res?.data?.error === false){
                const {data} = res?.data;
                dispatch(ticketsActions.apiCallSuccess(data));
                ToastSuccess(res?.data?.message)
            }else{
                throw error?.message
            }

        }catch(err){
            dispatch(ticketsActions.apiCallFailed(err));
            ToastError(err)
            console.log(err)
        }
    }
};
export const updateTicketsApi = (data)=> {
    return async (dispatch,getState)=>{
        console.log("data",data)

        const token = getState()?.LoginRes?.authResToken;
        dispatch(ticketsActions.apiCallRequested());
        try{
            const res = await axios({
                method:'POST',
                url:`/me/tickets/update`,
                data,
                headers:{
                    Authorization:`Bearer ${token}`
                }
            });
        
            const {error , message } = res?.data;
            if(res?.data?.error === false){
                const {data} = res?.data;
                dispatch(ticketsActions.updateTicketsInfo(data));
                ToastSuccess(res?.data?.message)
            }else{
                throw error?.message
            }

        }catch(err){
            dispatch(ticketsActions.apiCallFailed(err));
            ToastError(err)
            console.log(err)
        }
    }
};


export const ticketsActions = ticketSlice.actions;
export default ticketSlice.reducer;