import { combineReducers } from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from 'redux-persist/lib/storage/session'

// importing reducers below
import loginReducer from "./general/login";
import registerReducer from "./register/register";
import profileReducer from "./auth/profile";
import updatePasswordReducer from "./auth/updatePassword";
import createUserReducer from "./users/createUser";
import rolesReducer from "./roles/roles";
import offersReducer from "./offers/offers";
import usersReducers from "./users/users";
import deleteUserReducer from "./users/deleteUser";
import getUserReducer from "./users/getUser";
import updateUserReducer from "./users/updateUser";
import getOffersReducer from "./offers/getOffers";
import appsReducer from "./applications/applications";
import eventsReducer from "./events/events";
import storesReducers from "./stores/stores";
import analyticsReducers from "./analytics/analytics";
import productsReducer from "./products/products";
import mallReducer from "./general/mall";
import globalSearchReducer from "./globalsearch/globalSearch";
import forgetPasswordReducer from "./auth/forgetPassword";
import resetPasswordReducer from "./auth/resetPassword";
import notificationReducers from "./general/notificationForm";
import ticketReducers from './tickets/tickets';
import verifyEmailReducer from './auth/verifyEmail';
import generalReducer from "./general/general";


/***
 * Add to whitelist to store in localstorage
 * ***/
const persistConfig = {
  key:"root",
  storage: sessionStorage, 
  whitelist:["LoginRes", "ProfileRes", "mallsStore"]
};


/***
 * Add to reducers to update states in store
 * ***/
//All reducers names are start with capital letter 
const combineReducer =  combineReducers({
  LoginRes: loginReducer,
  RegisterRes:registerReducer,
  ProfileRes:profileReducer,
  updatePasswordRes:updatePasswordReducer,
  createUserRes:createUserReducer,
  rolesRes:rolesReducer,
  OffersStore:offersReducer,
  usersListRes:usersReducers,
  deleteUserRes:deleteUserReducer,
  getUserRes:getUserReducer,
  updateUserSuccessRes:updateUserReducer,
  getOffersRes:getOffersReducer,
  appsStore:appsReducer,
  eventsListRes:eventsReducer,
  storesRes:storesReducers,
  analyticsStore:analyticsReducers,
  productsRes:productsReducer,
  mallsStore:mallReducer,
  globalSearchList:globalSearchReducer,
  forgetPassword:forgetPasswordReducer,
  resetPassword:resetPasswordReducer,
  notifications:notificationReducers,
  ticketRes:ticketReducers,
  verifyEmailRes:verifyEmailReducer,
  general:generalReducer,
});




const rootReducer = (state, action) => {
  if (action.type === 'login/logoutAPISuccess') {
    sessionStorage.removeItem("persist:root");
    state =  {};
  }else{
  }
  return combineReducer(state,action)
}


export default persistReducer(persistConfig,rootReducer)
