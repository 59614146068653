import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";

import { logoutApi } from "../../store/general/login";
import { mallActions } from '../../store/general/mall';
import { offerActions } from "../../store/offers/offers";
import { storesActions } from "../../store/stores/stores";
import { eventsActions } from "../../store/events/events";
import { productActions } from "../../store/products/products";
import { globalSearchApi } from '../../store/globalsearch/globalSearch';
import { profilePostAPi, profileGetAPi } from "../../store/auth/profile";
import { getNotificationsApi, readNotification } from "../../store/general/notificationForm";



const TopNavbar = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const profileRes = useSelector(state => state?.ProfileRes?.profileRes);
	const selectedMall = useSelector(state => state?.mallsStore?.selectedMall);
	const selectedMallName = useSelector(state => state?.mallsStore?.selectedMallName);
	const SearchList = useSelector(state => state?.globalSearchList?.searchList);
	const notificationArr = useSelector(state=>state.notifications?.notificationsRes);
	const isLoadingProfile = useSelector(state => state?.ProfileRes?.isLoading);
	const isLoadingNotification = useSelector(state => state?.notifications?.isLoading);
	const lastcalledProfile = useSelector(state => state?.ProfileRes?.lastcalled);
	const lastcalledNotification = useSelector(state => state?.notifications?.lastcalled); 
	const assetsBaseUrl = useSelector(state => state?.general?.assetsBaseUrl);

	const [imgSrc, setImgSrc] = useState("");
	const [searchInput, setSearchInput] = useState("");
	const [filtered, setFiltered] = useState([])
	const [path, setPath] = useState("");

	const [currentNotify, setCurrentNotify] = useState(null);

	//console.log("notificationArr",notificationArr && notificationArr.filter(item=>item.status===0))

	function myFunction() {
		document.getElementById("topsearch").style.display = "block";
		document.getElementById("hidesearch").style.display = "none";
	}
	function hidelist() {
		document.getElementById("list").style.display = "none";
	}

	document.addEventListener('mouseup', function (e) {
		var container = document.getElementById('topsearch');
		var container1 = document.getElementById('hidesearch');
		if (container && !container.contains(e.target)) {
			container.style.display = 'none';
			container1.style.display = 'block';
		}
		//console.log('container1', container1);
		//console.log('container', container);
	});

	const debounce = (func) => {

		let timer;
		return function (...args) {
			const context = this;

			if (timer) clearTimeout(timer);
			timer = setTimeout(() => {
				timer = null;
				func.apply(context, args)
			}, 1000)
		}
	}

	const callApi = () => {
		dispatch(globalSearchApi({ term: searchInput?.toLowerCase() }))
	}
	const handleChange = (e) => {
		const { value } = e.target;
		setSearchInput(value)
		if (value) {
			dispatch(globalSearchApi({ term: value?.toLowerCase() }))
		}
		if (value.length == 0) {
			history.push({
				pathname: path,
				state: ""
			})
		}
	}

	const optimisedVersion = debounce(handleChange, callApi)

	useEffect(()=>{
		let currDt = new Date();
		currDt.setSeconds(currDt.getSeconds() - 5);
		if(isLoadingNotification === false && currDt > lastcalledNotification){
			dispatch(getNotificationsApi());
		}
		if(profileRes?.is_prospect === 1){
			if(isLoadingProfile === false && currDt > lastcalledProfile){
        		dispatch(profileGetAPi(profileRes));
			}
		}
	},[])

	useEffect(() => {
		if(currentNotify && currentNotify?.application_id)
		{
			if(currentNotify.status === 0 && currentNotify.title !== 'Request for Information'){
				dispatch(readNotification({'id': currentNotify?.id}));
				//dispatch(getNotificationsApi());
			}
			
			if(currentNotify.title=='Request for Information' && currentNotify.status === 0 && currentNotify?.data?.taskID){
				history.push("/applications/rfi/" + currentNotify?.id);
			}else if((currentNotify.title=='Offer Letter Received' && currentNotify.status === 0 && currentNotify?.data?.taskID)){
				history.push("/applications/review/" + currentNotify?.application_id + "/" + currentNotify.data.taskID);
			}else{
				history.push("/applications/view/" + currentNotify?.application_id);
			}
			setCurrentNotify(null);
		}
	},[currentNotify]);

	useEffect(() => {
		if (searchInput) {
			if (SearchList) {
				//console.log("SearchList", SearchList)
				let arr = []
				for (let property in SearchList) {
					//console.log(`==>${property}: ${SearchList[property]}`)
					if (SearchList[property]) {
						arr.push(property)
					}
				}
				if(document.getElementById("list")){
					document.getElementById("list").style.display = "block";
				}
				setFiltered(arr)
			}
		} else {
			setFiltered([])
		}
	}, [SearchList, searchInput]);

	const Logout = () => {
		dispatch(logoutApi())
	}

	const switchMall = () => {
		dispatch(mallActions.setSelectedMall(null));
		dispatch(storesActions.resetList());
		dispatch(offerActions.resetList());
		dispatch(eventsActions.resetList());
		dispatch(productActions.resetList());
		return false;
	}


	useEffect(() => {
		if (profileRes?.image) {
			let src = assetsBaseUrl + profileRes.image;
			setImgSrc(src)
		}else{
			setImgSrc("");
		}
	}, [profileRes]);

	useEffect(() => {
		if (profileRes?.device_token === '' && selectedMallName === null) {
			var firebaseConfig = {
				apiKey: "AIzaSyBSkxk61rwlV5JTozVCml4Rp-H7xBEv12I",
				authDomain: "dhm-app-7fbd0.firebaseapp.com",
				projectId: "dhm-app-7fbd0",
				storageBucket: "dhm-app-7fbd0.appspot.com",
				messagingSenderId: "203959402246",
				appId: "1:203959402246:web:863eb4dc7930e2c734a887",
				measurementId: "G-XKNLSYJP5Q"
			};

			const app = initializeApp(firebaseConfig);
			const messaging = getMessaging();
			getToken(
				messaging,
				{
					vapidKey: 'BAtMslH6hVR9umTSbGGZP0lhPYZKZ1J1N4HK_zE1i_921A3qKkDO4a0S42ovDcNGS75fn8VLqEcncKNJeTITmOk'
				}
			)
			.then((currentToken) => {
				if (currentToken) {
					//console.log(currentToken);
					const data = {
						token_update: 'yes',
						device_token: currentToken,
						device_type: 'browser',
					};
					dispatch(profilePostAPi(data))
				} else {
					// Show permission request UI
					//console.log('No registration token available. Request permission to generate one.');
					// ...
				}
			}).catch((err) => {
				//console.log('An error occurred while retrieving token. ', err);
				// ...
			});
		}
	}, [profileRes]);

	return (
		<nav className="main-header navbar navbar-expand navbar-white navbar-light">
			<ul className="navbar-nav">
				<li className="nav-item d-block d-md-block d-lg-none d-xl-none mr-3">
					<a className="nav-link p-0" data-widget="pushmenu" href="#" role="button" id="mob-menu-icon">
						<span></span>
						<span></span>
						<span></span>
					</a>
				</li>
				<li className={"nav-item d-none d-sm-none d-md-block d-lg-block d-xl-block " + ((selectedMallName === 'none') ? 'disp-none' : '')}>
					<h4 className="ml-1 mt-0 mb-0 text-dark">{(selectedMallName && selectedMallName !== 'none') ? selectedMallName +  ': ' : ''}</h4>
				</li>
				{" "}
				<li className="nav-item d-none d-sm-none d-md-block d-lg-block d-xl-block">
					<h4 className="ml-1 mt-0 mb-0 text-dark">{props?.title}</h4>
				</li>


			</ul>
			<ul className="navbar-nav ml-auto">
				<li className={"nav-item " + (selectedMallName === 'none' ? 'disp-none' : '')} id="topsearch">
					<div className="form-inline mt-1" >
						<div className="input-group input-group-sm">
							<input type="search" placeholder="Search" name="search" className="lochsr1" autoFocus onChange={optimisedVersion} />
						</div>
					</div>
					{(
						searchInput.length > 0 && filtered.length > 0 && (<ul id="list" className="list">
							{searchInput.length > 0 && filtered.map((item, index) => (
								<li key={index} onClick={() => {
									hidelist()
									setPath(item)
									return history.push({
										pathname: `/${item}`,
										state: searchInput,
										search: searchInput
									});
								}} >{searchInput} in {item}</li>
							))}
						</ul>)
					)}
				</li>
				<li id="hidesearch" className={selectedMall ? (selectedMallName === 'none' ? 'disp-none' : '') : 'disp-none'}>
					<button className="btn btn-navbar pt-2 " type="button" onClick={myFunction}>
						<img src="/dist/img/Icon-search.svg" alt="Logo" className="" width="18px" />
					</button>
				</li>
				<li className={selectedMall ? "nav-item dropdown" : 'disp-none'}>
					<a className="nav-link notifications" data-toggle="dropdown" href="#">
							{/* <img src="/dist/img/notification.svg" alt="Logo" className="" /> */}
						<i className="far fa-bell position-relative">
						{
							(notificationArr && notificationArr.filter(item=>item.status===0)?.length > 0)  ?
							<Fragment>
								<span className="notfcn-count position-absolute">{notificationArr && notificationArr.filter(item=>item.status===0)?.length}</span>
							</Fragment>
						 : ''
						}
						</i>
					</a>
					<div className="dropdown-menu notifidrop dropdown-menu-right pt-1 pb-2 pl-2 pr-2 mt-5">
						<div className="messagenot">
							<span className="dropdown-item dropdown-header text-left">Notifications</span>
							<span className="mt-3">
								<a href="#">
									<img src="/dist/img/cancel.png" alt="Logo" className="" width="12px" />
								</a>
							</span>
						</div>
						<div className="nothheight">
							{
								(notificationArr && notificationArr?.length > 0) ? notificationArr.map((item, index) => (

									<Fragment key={index}>
										<Link to="#" onClick={() => setCurrentNotify(item)} className={"dropdown-item notifidropitem notifidropitem1 mb-2 " + (item.status === 0 ? 'notifidropitem-unread' : '')}>
											<h5 className="mb-1">{item.title} - {item.application_id}</h5>
											<div className="messagenot">
												<h6 className="mb-0 mr-1">{item.description}</h6>
												<span className="float-right text-muted text-sm">{item.createdAt.split(' ').slice(0, 1).join()}</span>
											</div>
										</Link>
									</Fragment>

								)) : <div className="no-notification-div">No new notifications</div>
							}
							
						</div>
						{/* <a href="#" className="dropdown-item dropdown-footer">View all</a> */}
					</div>
				</li>
				<li className="nav-item dropdown">
					<div className="user-panel d-flex mt-1" data-toggle="dropdown">
						<div className="image d-none d-sm-none d-md-block d-lg-block d-xl-block">
							<img src={imgSrc ? imgSrc : `/dist/img/user_1.png`} className="" alt="User Image" style={{ height: "38px", width: "38px" }} />
						</div>
						<div className="info">
							<a href="#" className="d-block">{profileRes?.name} <img src="/dist/img/downarrow.svg" className="ml-1" alt="User Image" width="12px" /></a>
						</div>

					</div>
					<div className="dropdown-menu dropdown-menu-right">
						<div className={selectedMall ? "dropdown-item" : 'disp-none'}>
							<Link to="/profile" className="" onClick={() => history.push("/profile")}> <img src="/dist/img/profile.svg" alt="Logo" className="mr-2" /> Profile</Link>
							<hr className="mt-1 mb-1" />
						</div>
						<div className={(selectedMall && selectedMallName !== 'none') ? "dropdown-item" : 'disp-none'}>
							<Link to="/" className="" onClick={switchMall}> <img src="/dist/img/settings.svg" alt="switch mall" className="mr-2" /> Switch Mall</Link>
							<hr className="mt-1 mb-1" />
						</div>
						<div className="dropdown-item">
							<a href="#logout" className="" > <img src="/dist/img/logout.svg" alt="Logo" className="mr-2" /> Logout</a>
						</div>

					</div>
				</li>
			</ul>
			<div id="logout" className="overlay">
                <div className="popup popup2">
                    <div className="content">
                        <div className="title">
                            <h4>Do you want to Log out ? </h4>
                            {/* <p>Please Select any one Either From Desktop display or Mobile Display </p> */}
                        </div>
                        <div className="floatbtn">
                            <a href="#" className="btn-add btn-cancel mr-2">No</a>
                            <a href="#" className="btn-add" onClick={Logout} >Yes</a>
                        </div>
                    </div>
                </div>
            </div>
		</nav>
	);
}

export default TopNavbar;