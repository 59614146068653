import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";
// import { getToken } from "../../config";


const initialPasswordState = { isLoading: false, isError: "", verifyEmailRes: "" ,redirect:false};

const verifyEmailSlice = createSlice({
    name: "verifyEmail",
    initialState: initialPasswordState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.verifyEmailRes = action.payload;
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        resetState(state){
            state.isLoading = false;
            state.isError = "";
        }
    }
});


export const verifyEmailApi = (data) => {
    return async (dispatch,getState) => {
        // const token = getState()?.LoginRes?.authResToken;

        dispatch(verifyEmailActions.apiCallRequested());

        try {
            const res = await axios({
                method: "POST", url: `/tenant/verify-email`,
                 data,
                  headers: {
                    
                    // Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (!error) {
                const { message } = res?.data?.data;
                dispatch(verifyEmailActions.apiCallSuccess(message));
                ToastSuccess(message)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(verifyEmailActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const verifyEmailActions = verifyEmailSlice.actions;

export default verifyEmailSlice.reducer;