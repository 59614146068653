
import { Link } from "react-router-dom";
import { useSelector, connect } from 'react-redux';

import TopNavbar from "./topnavbar";
import ProspectSidebar from "./prospect-sidebar";
import TenantSidebar from "./tenant-sidebar";

const Navbar = (props) => {
	const isLogin = useSelector(state => state.LoginRes.authResToken);

	const is_prospect = (props.profileRes.profileRes) ? props.profileRes.profileRes.is_prospect : 0;
	if (isLogin && is_prospect === 1) {
		return (
				<div>
					<TopNavbar title={props?.title} selectedMall={props?.selectedMall}/>
					<ProspectSidebar />
				</div>
		);
	} else {
		return (
				<div>
					<TopNavbar title={props?.title} selectedMall={props?.selectedMall}/>
					<TenantSidebar />
				</div>
		);
	}
}

function mapStateToProps(state) {
	return { profileRes: state.ProfileRes };
}

export default connect(mapStateToProps)(Navbar);