import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";

const iniState = { isLoading: false, isError: "",productsList:null, createProduct: null,getProduct:null,  isDraftSave: null , progress: 0 , updateProductRes:null , submitFormSuccess:null, deleteRespose:null};

const productSlice = createSlice({
    name: "product",
    initialState: iniState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
			state.isDraftSave = false;
			state.deleteRespose = null;
            state.createProduct=null;
            state.updateProductRes=null;
            state.getProduct=null;
            state.submitFormSuccess=false;
        },
        apiCallSuccess(state,action){
            state.isLoading = false;
            state.productsList=action.payload;
        },
		apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        createProductSuccess(state, action) {
            state.isLoading = false;
            state.createProduct = action.payload;
            state.submitFormSuccess=true;
        },
        updateDraftStatus(state) {
            state.isDraftSave = true;
        },
        getProductSuccess(state, action) {
            state.isLoading = false;
            state.getProduct = action.payload;
        },
        updateProgress(state, action) {
            state.progress = action.payload;
        },
        updateProductSuccess(state,action){
            state.isLoading=false;
            state.updateProductRes=action.payload;
            state.submitFormSuccess=true;

        },
        deleteProductSuccess(state, action) {
            state.isLoading = false;
            state.deleteRespose = action.payload;
        },
        emptyState(state){
            state.isDraftSave = false;
			state.deleteRespose = null;
            state.createProduct=null;
            state.updateProductRes=null;
            state.getProduct=null;
            state.submitFormSuccess=false;
        },
        resetList(state){
            state.productsList = null;
        }
      
    },
}); 



export const getProductsAPi = () => {
    return async (dispatch,getState) => {
        dispatch(productActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        const selectedMall = getState()?.mallsStore?.selectedMall;

        try {
            const res = await axios({
					method: "GET",
					url: '/mds/products/list?mid=' + selectedMall,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                //console.log("res products",res.data.data.data)
            const { data   } = res?.data;
			if(data?.success){
                dispatch(productActions.apiCallSuccess(data?.data));
            }else{
                throw data?.message
            }
        } catch (error) {
            dispatch(productActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getProductById = (productId) => {
    return async (dispatch,getState) => {
        dispatch(productActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        try {
            const res = await axios({
					method: "GET",
					url: `/mds/products/get/${productId}`,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                
            if(res?.data?.data?.success){   
                const { data } = res?.data; 
                dispatch(productActions.getProductSuccess(data?.data));
            }else{
                throw "someThing wrong !";
            }
        } catch (error) {
            dispatch(productActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const updateProductApi = (formData,id) => {
    return async (dispatch,getState) => {
        dispatch(productActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "POST",
					url: `/mds/products/update/${id}`,
					data: formData,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                
            //console.log("res",res)
            if(res?.data?.data?.success){
                // const { data } = res?.data?.data; 
                    dispatch(getProductsAPi())
                    dispatch(productActions.updateProductSuccess(res?.data?.data?.message));
                    ToastSuccess(res?.data?.data?.message)

            }else{
                throw res?.data?.data?.message
            }
        } catch (error) {
            dispatch(productActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const deleteProductApi = (data) => {
    return async (dispatch, getState) => {
		const bodyData = { "itemToDelete": data };
        const token = getState()?.LoginRes?.authResToken;
        dispatch(productActions.apiCallRequested());
        try {
            const res = await axios({
                method: "POST",
				data: bodyData,
				url: `/mds/products/delete`, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { data} = res?.data;
			//console.log(data?.success);
            if (data?.success === true) {
                dispatch(productActions.deleteProductSuccess(data?.message));
                dispatch(getProductsAPi())
                //console.log('here');
				ToastSuccess(data?.message);
            } else {
				//console.log('here 2');
                throw data?.message;
            }
        } catch (error) {
            dispatch(productActions.apiCallFailed(error));
            console.log(error)
        }
    }
};




export const createProductAPI = (formData) => {
    return async (dispatch,getState) => {
        dispatch(productActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "POST",
					url: `/mds/products/add`,
					data: formData,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                
            if(res?.data?.data?.success){
                const { data } = res?.data?.data; 
                dispatch(productActions.createProductSuccess(data));
				if(formData.saveMethod === 'save'){
                    dispatch(productActions.updateDraftStatus());
                    ToastSuccess(res?.data?.data?.message)
                    dispatch(getProductsAPi())
                    
				}else{
                    ToastSuccess(res?.data?.data?.message)
                    dispatch(getProductsAPi())
                    dispatch(productActions.formSubmittedSuccess());
                }
            }else{
                throw "someThing wrong !"
            }
        } catch (error) {
            dispatch(productActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const productActions = productSlice.actions;

export default productSlice.reducer;
