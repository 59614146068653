import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastError } from "../../common/Toast";
import { profileGetAPi, profileActions } from "../auth/profile";
/***
 * Login Reducer initial state
 * ***/
const initialAuthState = { isAuthenticated: false, isLoading: false, isError: "", authResToken: null };

const authSlice = createSlice({
  name: "login",
  initialState: initialAuthState,
  reducers: {
    /***
     * Login API Reducers
     * ***/
    loginAPIRequested(state) {
      state.isLoading = true;
      state.isAuthenticated = false;
      state.isError = "";
    },
    loginAPISuccess(state, action) {
      state.isLoading = false;
      state.isError = "";
      state.isAuthenticated = true;
      state.authResToken = action.payload;
    },
    loginAPIFailure(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.isError = action.payload;
    },

    /***
     * Logout API Reducers
     * ***/

     logoutAPIRequested(state) {
      state.isLoading = true;
      state.isError = "";
    },
    logoutAPISuccess(state, action) {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.authResToken = null;
    },
    logoutAPIFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },

    resetLoading(state) {
      state.isLoading = false;
    },
    

  },
});

/***
 * Login API
 * ***/
export const loginApi = (data) => {
  return async (dispatch) => {
    dispatch(authActions.loginAPIRequested());
    try {
      const res = await axios({ method: "POST", url: `/tenant/login`, data, });
      const { error, message } = res?.data;
      if (!error) {
        const { token } = res?.data?.data;
        dispatch(authActions.loginAPISuccess(token));
        dispatch(profileActions.resetLoading());
        dispatch(profileGetAPi());
      } else {
        throw message;
      }
    } catch (error) {
      //dispatch login failure
      dispatch(authActions.loginAPIFailure(error));
      //console.log(error)
      if(error && typeof error!='object'){
        ToastError(error)
      }
      
    }
  }
};

/***
 * Logout API
 * ***/
 export const logoutApi = () => {
  return async (dispatch,getState) => {
    const token = getState()?.LoginRes?.authResToken;
    dispatch(authActions.logoutAPIRequested());
    try {
      const res = await axios({ method: "GET", url: `/tenant/logout`,
      headers: {
        Authorization: `Bearer ${token}`
    }
    });
    const { error, message } = res?.data;
      if (!error) {
        const { message } = res?.data?.data;
        dispatch(authActions.logoutAPISuccess());
      } else {
        throw message;
      }
      
      //console.log("res",res)
      
    } catch (error) {
      //dispatch login failure
      dispatch(authActions.logoutAPIFailure(error));
      console.log(error)
    }
  }
};


export const authActions = authSlice.actions;

export default authSlice.reducer;
