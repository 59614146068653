import React, {Fragment, useEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { mallGetApi, mallActions } from '../store/general/mall';
import { profileGetAPi } from "../store/auth/profile";
import Navbar from '../views/general/navbar';
import PageLoader from "../common/PageLoader";

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
    /***
     * getting token from redux store
     * ***/ 
    const dispatch = useDispatch();
    const token = useSelector(state => state.LoginRes.authResToken);
    const profileRes = useSelector(state => state?.ProfileRes?.profileRes);
    const assetsBaseUrl = useSelector(state => state?.general?.assetsBaseUrl);

	const checkModuleAccess = (location) => {
		let hasAccess = false;
        let module = location.split('/');
        module = (module.length > 1) ? '/'  +  module[1] : '/';
        if(module === '/' || module === '/profile'){
            hasAccess = true;
        }else{
            profileRes?.modules?.forEach(item =>{
                if(item && item?.base_url === module){
                    hasAccess = true;
                }
            });
        }
		return hasAccess;
	};

    //console.log('profileRes test', profileRes);

    useEffect(() => {
        if(profileRes === null){
        	dispatch(profileGetAPi(profileRes));
		}
        if(profileRes?.is_prospect === 1){
            dispatch(mallActions.setSelectedMall('none'));
            dispatch(mallActions.setSelectedMallName('none'));
        }
    }, [profileRes, dispatch]);

    const mallsList = useSelector(state=>state?.mallsStore?.mallList);
	const selectedMall = useSelector(state=>state?.mallsStore?.selectedMall);
    const isLoading = useSelector(state => state?.mallsStore?.isLoading);
    const setSelMall = (item) => {
		dispatch(mallActions.setSelectedMall(item.encryptedId));
        dispatch(mallActions.setSelectedMallName(item.name));
        dispatch(profileGetAPi(profileRes));
	};

    useEffect(() => {
		if(mallsList === null){
        	dispatch(mallGetApi());
		}
    }, [mallsList, dispatch]);

    const mallsHTML = profileRes?.malls?.map((item, key) => {
		return(
            <Fragment key={key}>
			<div className="col-md-6" onClick={()=> {setSelMall(item)} }>
				<div className="card">
					<div className="card-horizontal">
						<div className="card-body">
							<span className="ml-0">{item.name}</span>
							<p className="card-text mt-2 mb-0">
                                Location: Dubai, United Arab Emirates Time: 10 AM - 12 PM
							</p>
						</div>
						<div className="img-square-wrapper">
                            <img className="" src={assetsBaseUrl + item.logo} alt="Card image cap" width="120px" height="130px" />
						</div>
					</div>
				</div>
			</div></Fragment>
		)
	});

    return (
        <Route {...rest} render={
            props => {
                if (token) {
                    if(selectedMall === null || (profileRes?.is_prospect === 0 && selectedMall === 'none')){
                        if(props.location.pathname !== '/'){
                            return <Redirect to={
                                {
                                    pathname: '/',
                                    state: { from: props.location }
                                }
                            } />
                        }
                        return <Fragment>
                            <div className="wrapper">
                                <PageLoader isActive={isLoading} /> <Navbar title="Select a Mall" />
                                    <div className="content-wrapper">
                                        <section className="contentchoose"><div className="container-fluid ">
                                        <div className="row"><div className="col-md-12 col-xl-12"><div className="row selecta mt-3">
                                        {mallsHTML}
                                        </div></div></div>
                                        </div></section>
                                    </div>
                            </div>
                        </Fragment>
                    }else if(profileRes?.is_prospect === 1){
                        if(props.location.pathname.startsWith('/applications') || props.location.pathname.startsWith('/profile') || props.location.pathname.startsWith('/page'))
                            return <Component {...rest} {...props} key={props?.match?.params?.id} />
                        else{
                            return <Redirect to={
                                {
                                    pathname: '/applications',
                                    state: { from: props.location }
                                }
                            } />
                        }
                    }else{
                        if(checkModuleAccess(props.location.pathname)){
                            return <Component {...rest} {...props} key={props?.match?.params?.id} />
                        }else{
                            return <Redirect to={
                                { pathname: '/', state: { from: props.location } }
                            } />
                        }
                    }
                } else {
                    return <Redirect to={
                        {
                            pathname: '/login',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }
        } />
    )
}

export default ProtectedRoute;