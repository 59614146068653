import { createSlice } from "@reduxjs/toolkit";
 
const initialData = { pageTitle: 'Tenant Management System', htmlTitle: 'TMS', assetsBaseUrl: 'https://mdsassets.emaarmalls.com/mds'};
// const initialData = { pageTitle: 'Tenant Management System', htmlTitle: 'TMS', assetsBaseUrl: 'https://stgmds.emaarmalls.com'};

const generalSlice = createSlice({
  name: "login",
  initialState: initialData,
  reducers: {
    updateData(state, action) {
      state.pageTitle = action.payload.pageTitle;
      state.htmlTitle = action.payload.htmlTitle;
    },
    updatePageTitle(state, action) {
      document.title = action.payload.title + ' - '  + state.pageTitle;
    },
  },
});

export const generalActions = generalSlice.actions;

export default generalSlice.reducer;
