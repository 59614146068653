import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";

/***
 * Register Reducer initial state
 * ***/
const initialAuthState = { registerRes: null, isLoading: false, isError: "" , isSubmitted:false };

const regsiterSlice = createSlice({
  name: "register",
  initialState: initialAuthState,
  reducers: {
    registerRequested(state) {
      state.isLoading = true;
      state.isError = "";
      state.isSubmitted=false;
    },
    registerSuccess(state, action) {
      state.isLoading = false;
      state.registerRes = action.payload;
      state.isSubmitted=true
    },
    registerFailure(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
      state.isSubmitted=false
    },
    resetState(state,action){
      state.isLoading = false;
      state.isError = "";
      state.isSubmitted=false;
    }
  },
});

/***
 * Register API
 * ***/
export const registerApi = (formData) => {
  return async (dispatch) => {
    dispatch(registerActions.registerRequested());
    try {
      const res = await axios({ method: "POST", url: `/tenant/register`, data: formData });
      const { error, message } = res?.data;
      if (!error) {
        const { data , message} = res?.data;
        
        dispatch(registerActions.registerSuccess(data))
        ToastSuccess(message)
      } else {
        throw message;
      }
    } catch (error) {
      dispatch(registerActions.registerFailure(error))
      console.log(error)
    }
  }
};

export const registerActions = regsiterSlice.actions;

export default regsiterSlice.reducer;
