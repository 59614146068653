import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";
import { getToken } from "../../config";


const iniState = { isLoading: false, isError: "", offersList:null };

const offersListSlice = createSlice({
    name: "offers",
    initialState: iniState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = ""
        },
        apiCallSuccess(state,action){
            state.isLoading = false;
            state.offersList=action.payload;
        },
		apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
    },
});


export const offersActions = offersListSlice.actions;

export default offersListSlice.reducer;
