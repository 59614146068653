import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

/***
 * Profile Reducer initial state
 * ***/
const initialProfileState = { isLoading: false, isError: "", Roles: null,   };



const roleSlice = createSlice({
    name: "roles",
    initialState: initialProfileState,
    reducers: {
        /**
         * Profile GET API Reducers
         * **/
        GetApiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        GetApiCallSuccess(state, action) {
            state.isLoading = false;
            state.Roles = action.payload;
        },
        GetApiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
    },
});

const getMallId = (mallEncryptedId, mallsList) => {
    let mallID = 1;
    mallsList?.forEach(item => {
        if(item.encryptedId === mallEncryptedId){
            mallID = item.id;
        }
    });
    return mallID;
};

/***
 * Profile GET API
 * ***/
export const mallRolesGetApi = (profileRes) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        const mallsList = profileRes?.malls;
        const selectedMall = getState()?.mallsStore?.selectedMall;

        let mallID = getMallId(selectedMall, mallsList);
        dispatch(roleActions.GetApiCallRequested());
        try {
            const res = await axios({
                method: "GET", url: `/tenant/mall-roles?mall=` + mallID, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message ,data} = res?.data;
            if (!error) {
                dispatch(roleActions.GetApiCallSuccess(data?.roles));
            } else {
                throw message;
            }

        } catch (error) {
            dispatch(roleActions.GetApiCallFailed(error));
            console.log(error)
        }
    }
};



export const roleActions = roleSlice.actions;

export default roleSlice.reducer;
