import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";


/***
 * createUsser Reducer initial state
 * ***/
 const initilCreateUserState = { isLoading: false, isError: "", usersListRes: null ,};


 const userListSlice = createSlice({
     name:"userList",
     initialState : initilCreateUserState,

     reducers:{
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.usersListRes = action.payload;
            
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
           
        },
     }
 });


 /***
 *  USER LIST POST API
 * ***/
export const UserListApi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(userListActions.apiCallRequested());
        try {
            const res = await axios({
                method: "GET", url: `/users/list`, data, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (!error) {
                // console.log("userslist==>",res)
                const { data } = res?.data;
                dispatch(userListActions.apiCallSuccess(data?.list));
                // ToastSuccess(data?.info)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(userListActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const userListActions = userListSlice.actions;

export default userListSlice.reducer;


