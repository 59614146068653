import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess, ToastError } from "../../common/Toast";

/***
 * Profile Reducer initial state
 * ***/
const initialProfileState = { isLoading: false, isError: "", profileRes: null, postData: "", lastcalled:false, isUpdating:false };

const profileSlice = createSlice({
    name: "profile",
    initialState: initialProfileState,
    reducers: {
        /**
         * Profile GET API Reducers
         * **/
        GetApiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        GetApiCallSuccess(state, action) {
            state.isLoading = false;
            state.profileRes = action.payload;
			//console.log("payload", action.payload);
        },
        GetApiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        apiSetTime(state) {
          state.lastcalled = new Date();
        },

        /**
        * Profile POST API Reducers
        * **/

        PostApiCallRequested(state) {
            state.isLoading = true;
            state.isUpdating = true;
            state.isError = "";
        },
        PostApiCallSuccess(state, action) {
            state.isLoading = false;
            state.isUpdating = false;
            state.postData = action.payload
        },
        PostApiCallFailed(state, action) {
            state.isLoading = false;
            state.isUpdating = false;
            state.isError = action.payload;
        },
        resetLoading(state) {
            state.isLoading = false;
        }

    },
});

const getMallId = (mallEncryptedId, mallsList) => {
    let mallID = 1;
    mallsList?.forEach(item => {
        if(item.encryptedId === mallEncryptedId){
            mallID = item.id;
        }
    });
    return mallID;
};

/***
 * Profile GET API
 * ***/
export const profileGetAPi = (profileRes) => {
    return async (dispatch, getState) => {
        let currentlyLoading = getState()?.ProfileRes?.isLoading;
        if(currentlyLoading === false){
            const token = getState()?.LoginRes?.authResToken;
            dispatch(profileActions.GetApiCallRequested());
            dispatch(profileActions.apiSetTime());

            let profileResL = getState()?.ProfileRes?.profileRes

            const mallsList = getState()?.ProfileRes?.profileRes?.malls;
            const selectedMall = getState()?.mallsStore?.selectedMall;

            console.log('here profileResL', profileResL);
            console.log(selectedMall);

            let mallID = getMallId(selectedMall, mallsList);
            try {
                const res = await axios({
                    method: "GET", url: '/tenant/info?mall=' + mallID, headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { error, message } = res?.data;
                if (!error) {
                    const { tenant } = res?.data?.data;
                    dispatch(profileActions.GetApiCallSuccess(tenant));
                } else {
                    throw message;
                }

            } catch (error) {
                dispatch(profileActions.GetApiCallFailed(error));
                console.log(error)
            }
        }
    }
};

/***
 * Profile POST API
 * ***/
export const profilePostAPi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        let currentlyUpdating = getState()?.ProfileRes?.isUpdating;
        if(currentlyUpdating === false){
            dispatch(profileActions.PostApiCallRequested());
            try {
                const res = await axios({
                    method: "POST", url: `/tenant/update`, data, headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const { error, message } = res?.data;
                if (error === false) {
                    const { data } = res?.data;
                    dispatch(profileActions.PostApiCallSuccess(data?.info));
                    ToastSuccess(data?.info)
                    dispatch(profileGetAPi())
                } else {
                    ToastError("Unable to connect to API");
                    dispatch(profileActions.PostApiCallFailed(null));
                }
            } catch (error) {
                dispatch(profileActions.PostApiCallFailed(error));
                console.log(error)
            }
        }
    }
};

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;
