import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialMallState = { isLoading: false, isError: "", mallList: null, selectedMall: null, selectedMallName: null };

const mallSlice = createSlice({
    name: "mall",
    initialState: initialMallState,
    reducers: {
        getApiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        getApiCallSuccess(state, action) {
            state.isLoading = false;
            state.mallList = action.payload;
			//console.log("payload", action.payload);
        },
        getApiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        setSelectedMall(state, action) {
            //console.log("payload", action);
            state.selectedMall = action.payload;
        },
        setSelectedMallName(state, action) {
            //console.log("payload", action);
            state.selectedMallName = action.payload;
        }
    },
});

export const mallGetApi = () => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(mallActions.getApiCallRequested());
        try {
            const res = await axios({
                method: "GET", 
                url: `/mds/mall/list`, 
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (error === false) {
                //console.log(res?.data?.data);
                dispatch(mallActions.getApiCallSuccess(res?.data?.data));
            } else {
                throw message;
            }

        } catch (error) {
            dispatch(mallActions.getApiCallFailed(error));
            console.log(error)
        }
    }
};

export const mallActions = mallSlice.actions;

export default mallSlice.reducer;
