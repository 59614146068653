import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const ProspectSidebar = () => {
	const selectedMall = useSelector(state => state?.mallsStore?.selectedMall);
	let bypassURL = '';
	return (
		<aside className="main-sidebar sidebar-dark-primary">
            <Link to="/" className="brand-link mt-2">
                <img src="/dist/img/logoemar.svg" alt="Logo" className="" />
            </Link>
            <div className="sidebar">
                <nav className="mt-4">
                  <ul className={selectedMall ? 'nav nav-pills nav-sidebar flex-column' : 'disp-none'} >
					<li className="nav-item">
						<Link to="/applications" className="nav-link pl-0">
							<img src="/dist/img/store.svg" alt="Logo" className="mr-3" />My Applications
						</Link>
					</li>
                  </ul>
                </nav>
              </div>
        </aside>
	);
}
 
export default ProspectSidebar;