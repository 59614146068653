import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastError, ToastSuccess } from "../../common/Toast";


const iniState = { isLoading: false, isError: "", storesListRes:null ,  progress: 0  ,getStoreRes:null , updateStoreRes:null , submitFormSuccess:null , storesCommonData:null ,pagination:null};

const storesListSlice = createSlice({
    name: "stores",
    initialState: iniState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
            state.submitFormSuccess=false;
            state.getStoreRes=null;
        },
        apiCallSuccess(state,action){
            state.isLoading = false;
            state.storesListRes=action.payload.data
            state.pagination=action.payload.pagination
        },
		apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        getStoreByIdSuccess(state,action){
            state.isLoading = false;
            state.getStoreRes= action.payload;
        },
        updateStoreSuccess(state,action){
            state.isLoading = false;
            state.updateStoreRes=action.payload;
            state.submitFormSuccess=true;
        },
        getStoresCommonDataSuccess(state,action){
            state.isLoading = false;
            state.storesCommonData=action.payload
        },
        emptyState(state){
            state.isError = "";
            state.submitFormSuccess=false;
            state.getStoreRes=null;
        },
        updateProgress(state, action) {
            state.progress = action.payload;
        },
        resetList(state){
            state.storesListRes = null;
        }
       
    },
}); 


export const getStoresApi = () => {
    
    return async (dispatch,getState) => {
               
        dispatch(storesActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        const selectedMall = getState()?.mallsStore?.selectedMall;
        
        try {
            const res = await axios({
					method: "GET",
					url: '/mds/stores/list?mid=' + selectedMall,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
            const { error } = res?.data;
			if(error === false){
                // console.log(res?.data?.data?.data);
                dispatch(storesActions.apiCallSuccess({
                    data:res?.data?.data?.data,
                    pagination:null
                }));

            }else{
                throw "something went Wrong"
            }
        } catch (error) {
            dispatch(storesActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const getStoreByIdApi = (storeId) => {
    return async (dispatch,getState) => {
        dispatch(storesActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "GET",
					url: `/mds/stores/get/${storeId}`,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                const { data, message, error} = res?.data; 
            if(error === false){
                console.log("data store", data)
                dispatch(storesActions.getStoreByIdSuccess(data?.data));
            }else{
                throw message;
            }
        } catch (error) {
            dispatch(storesActions.apiCallFailed(error));
            ToastError(error)
            console.log(error)
        }
    }
};

export const updateStoreApi = (formData,id) => {
    return async (dispatch,getState) => {
        dispatch(storesActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "POST",
					url: `/mds/stores/update/${id}`,
					data: formData,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                
            console.log("res",res)
            if(res?.data?.data?.success){
                  const { data } = res?.data; 
                    dispatch(getStoresApi())
                    dispatch(storesActions.updateStoreSuccess(data?.message));
                    ToastSuccess(data?.message)

            }else{
                throw res.data.message
            }
        } catch (error) {
            dispatch(storesActions.apiCallFailed(error));
            console.log("error",error)
        }
    }
};

export const getStoresCommonData = () => {
    return async (dispatch,getState) => {
        dispatch(storesActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        const selectedMall = getState()?.mallsStore?.selectedMall;
        try {
            const res = await axios({
					method: "GET",
                    url: `/mds/metadata/list?mid=` + selectedMall,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
            const { data , error } = res?.data;
			if(error === false){
                dispatch(storesActions.getStoresCommonDataSuccess(res?.data?.data));
            }else{
                throw res.data.message
            }
        } catch (error) {
            dispatch(storesActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const storesActions = storesListSlice.actions;

export default storesListSlice.reducer;