import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";
import { UserListApi } from "./users";


/***
 * createUsser Reducer initial state
 * ***/
 const initilCreateUserState = { isLoading: false, isError: "", deleteUserRes: null ,};


 const deleteUserSlice = createSlice({
     name:"deleteUser",
     initialState : initilCreateUserState,

     reducers:{
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.deleteUserRes = action.payload;
            
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
           
        },
     }
 });


 /***
 *  USER LIST POST API
 * ***/
export const deleteUserApi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(deleteUserActions.apiCallRequested());
        try {
            const res = await axios({
                method: "GET", url: `/users/delete/${data}`, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (error === false) {
                const { data } = res?.data;
                dispatch(deleteUserActions.apiCallSuccess(data?.list));
                ToastSuccess(message);
                dispatch(UserListApi())
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(deleteUserActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const deleteUserActions = deleteUserSlice.actions;

export default deleteUserSlice.reducer;


