import App from './App';
import React from 'react';
import axios from "axios";
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {store , persistor} from "./store/index";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

// axios.defaults.withCredentials = true;
// axios.defaults.baseURL = "http://localhost/test/curl.php?url=https://apidev.emaar.com/ims/v1";

axios.defaults.baseURL = "https://api.emaar.com/ims/v1";

axios.interceptors.response.use(function (response) {
  if(response.data?.error === 'Unauthenticated.') {
    sessionStorage.removeItem("persist:root");
    document.location.href = '/login';
    return Promise.reject(null);
  }
  return response;
}, function (error) {
  return error;
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
        <App />
    </PersistGate>
    </Provider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
