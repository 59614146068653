import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

/***
 * Profile Reducer initial state
 * ***/
const initialGlobalSearchState = { isLoading: false, isError: "", searchList: null,   };



const searchSlice = createSlice({
    name: "search",
    initialState: initialGlobalSearchState,
    reducers: {
        /**
         *  GET API Reducers
         * **/
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.searchList = action.payload;
        },
        apiCallFailded(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
    },
});

/***
 *  GET API
 * ***/
export const globalSearchApi = (searchInput) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(searchActions.apiCallRequested());
        try {
            const res = await axios({
                url: `/mds/search`,
                 method: "POST",
                 data:searchInput,
                  headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            console.log("res search",res)
            const { error, message ,data} = res?.data;
            if (!error) {
                dispatch(searchActions.apiCallSuccess(data?.results));
            } else {
                throw message;
            }

        } catch (error) {
            dispatch(searchActions.apiCallFailded(error));
            console.log(error)
        }
    }
};



export const searchActions = searchSlice.actions;

export default searchSlice.reducer;
