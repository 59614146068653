import React from "react";
import Routes from './routes/Routes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "./common/PageLoader";
import { useSelector } from 'react-redux';

// import "../node_modules/jquery/dist/jquery.min.js";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import './App.css'


function App() {
  const isLoading = useSelector(state => state?.LoginRes?.isLoading);
  const registerLoading =  useSelector(state=>state.RegisterRes.isLoading);

  const globalSearchLoading =  useSelector(state=>state?.globalSearchList?.isLoading)

  return (
  <PageLoader isActive={isLoading || globalSearchLoading || registerLoading}>
    <>
   <ToastContainer
      draggable
      pauseOnHover
      hideProgressBar={true}
      autoClose={5000}
      position="top-right"
      theme="colored"
    />
    <Routes />
    </>
    </PageLoader>
  );
}

export default App;
