import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastError, ToastSuccess } from "../../common/Toast";
/***
 * Login Reducer initial state
 * ***/
const notificationState = { isLoading: false, isError: "", notificationsRes:null,  readNotification:null, success:false, lastcalled:false};

const notificationSlice = createSlice({
  name: "notificationForm",
  initialState: notificationState,
  reducers: {
    
     apiCallRequested(state) {
      state.isLoading = true;
      state.isError = "";
      state.success=false;
    },
    apiSetTime(state) {
      state.lastcalled = new Date();
    },
    apiCallSuccess(state, action) {
      state.isLoading = false;
      state.data = null;
    },
    apiCallFailed(state, action) {
      state.isLoading = false;
      state.isError = action.payload;
    },
   notificationsSuccess(state,action){
     state.isLoading = false;
     state.notificationsRes = action.payload;
   },
   readNotificationsSuccess(state,action){
    state.isLoading = false;
    state.readNotification = action.payload;
  },
   formSubmissionSuccess(state,action){
     state.isLoading=false;
     state.success=true;
   },
   resetState(state){
     state.success=false
   }
  },
});


export const notificationFormApi = (formData) => {
    return async (dispatch,getState) => {
        const token = getState()?.LoginRes?.authResToken;

        dispatch(notificationActions.apiCallRequested());
        try {
            const res = await axios({
					method: "POST",
					url: `/baw/update-rfi`,
					data: formData,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
        if(!res.data.error){
          console.log("notification form data ",res.data)
          ToastSuccess(res.data.message)
          dispatch(notificationActions.formSubmissionSuccess());
        }else{
          throw res.data.message
        }
        } catch (error) {
            ToastError(error)
            dispatch(notificationActions.apiCallFailed(error));
            console.log(error)
        }
    }
};
export const getNotificationsApi = () => {
    return async (dispatch,getState) => {
        const token = getState()?.LoginRes?.authResToken;

        dispatch(notificationActions.apiCallRequested());
        dispatch(notificationActions.apiSetTime());
        try {
            const res = await axios({
					method: "GET",
					url: `/tenant/get-notification`,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
          const {data} = res;
          if(data.error===false){
            dispatch(notificationActions.notificationsSuccess(data?.data))
          }
        } catch (error) {
            dispatch(notificationActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const readNotification = (formData) => {
  return async (dispatch,getState) => {
      const token = getState()?.LoginRes?.authResToken;

      dispatch(notificationActions.apiCallRequested());
      try {
          const res = await axios({
            method: "POST",
            url: `/tenant/read-notification`,
            data: formData,
            headers:{
              Authorization:`Bearer ${token}`
            }
          });

          const {data} = res;
          if(data.error===false){
            dispatch(notificationActions.readNotificationsSuccess(data?.data));
            dispatch(getNotificationsApi());
          }
      } catch (error) {
          dispatch(notificationActions.apiCallFailed(error));
          console.log(error)
      }
  }
};


export const notificationActions = notificationSlice.actions;

export default notificationSlice.reducer;
