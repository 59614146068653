import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";
import { getToken } from "../../config";


const initialPasswordState = { isLoading: false, isError: "", updatePasswordRes: "" ,redirect:false};

const resetPasswordSlice = createSlice({
    name: "Reset Password",
    initialState: initialPasswordState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
            state.redirect=false
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.updatePasswordRes = action.payload;
            state.redirect=true
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
            state.redirect=false;
        },
        resetState(state){
            state.isLoading = false;
            state.isError = "";
            state.redirect=false
        }
    }
});


export const resetPasswordAPi = (data) => {
    return async (dispatch,getState) => {
        const token = getState()?.LoginRes?.authResToken;

        dispatch(resetPasswordActions.apiCallRequested());

        try { 
            const res = await axios({
                method: "POST", url: `/tenant/reset-password`,
                 data,
                  headers: {
                    
                    // Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (!error) {
                const { message } = res?.data?.data;
                dispatch(resetPasswordActions.apiCallSuccess(message));
                ToastSuccess(message)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(resetPasswordActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const resetPasswordActions = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;