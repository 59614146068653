/***
 * getting token from localstorage
 * ***/ 
export const getToken = ()=>{
    let parseToken = JSON.parse(sessionStorage.getItem('persist:root'));
    let token =  JSON.parse(parseToken.LoginRes)?.authResToken;
    return token;

}

export const getCountries = ()=>{
    const countries = [
        {'value': '1', 'key': 'ALBANIA', 'code': '355'},
        {'value': '2', 'key': 'ALGERIA', 'code': '213'},
        {'value': '3', 'key': 'AMERICAN SAMOA', 'code': '1684'},
        {'value': '4', 'key': 'ANDORRA', 'code': '376'},
        {'value': '5', 'key': 'BRITISH INDIAN OCEAN TERRITORY', 'code': '246'},
        {'value': '6', 'key': 'ANGOLA', 'code': '244'},
        {'value': '7', 'key': 'ANGUILLA', 'code': '1264'},
        {'value': '8', 'key': 'ANTARCTICA', 'code': '0'},
        {'value': '9', 'key': 'ANTIGUA AND BARBUDA', 'code': '1268'},
        {'value': '10', 'key': 'ARGENTINA', 'code': '54'},
        {'value': '11', 'key': 'ARMENIA', 'code': '374'},
        {'value': '12', 'key': 'ARUBA', 'code': '297'},
        {'value': '13', 'key': 'AUSTRALIA', 'code': '61'},
        {'value': '14', 'key': 'AUSTRIA', 'code': '43'},
        {'value': '15', 'key': 'AZERBAIJAN', 'code': '994'},
        {'value': '16', 'key': 'BAHAMAS', 'code': '1242'},
        {'value': '17', 'key': 'BAHRAIN', 'code': '973'},
        {'value': '18', 'key': 'BANGLADESH', 'code': '880'},
        {'value': '19', 'key': 'BARBADOS', 'code': '1246'},
        {'value': '20', 'key': 'BELARUS', 'code': '375'},
        {'value': '21', 'key': 'BELGIUM', 'code': '32'},
        {'value': '22', 'key': 'BELIZE', 'code': '501'},
        {'value': '23', 'key': 'BENIN', 'code': '229'},
        {'value': '24', 'key': 'BERMUDA', 'code': '1441'},
        {'value': '25', 'key': 'BHUTAN', 'code': '975'},
        {'value': '26', 'key': 'BOLIVIA', 'code': '591'},
        {'value': '27', 'key': 'BOSNIA AND HERZEGOVINA', 'code': '387'},
        {'value': '28', 'key': 'BOTSWANA', 'code': '267'},
        {'value': '29', 'key': 'BOUVET ISLAND', 'code': '0'},
        {'value': '30', 'key': 'BRAZIL', 'code': '55'},
        {'value': '31', 'key': 'BRUNEI DARUSSALAM', 'code': '673'},
        {'value': '32', 'key': 'BULGARIA', 'code': '359'},
        {'value': '33', 'key': 'BURKINA FASO', 'code': '226'},
        {'value': '34', 'key': 'BURUNDI', 'code': '257'},
        {'value': '35', 'key': 'CAMBODIA', 'code': '855'},
        {'value': '36', 'key': 'CAMEROON', 'code': '237'},
        {'value': '37', 'key': 'CANADA', 'code': '1'},
        {'value': '38', 'key': 'CAPE VERDE', 'code': '238'},
        {'value': '39', 'key': 'CAYMAN ISLANDS', 'code': '1345'},
        {'value': '40', 'key': 'CENTRAL AFRICAN REPUBLIC', 'code': '236'},
        {'value': '41', 'key': 'CHAD', 'code': '235'},
        {'value': '42', 'key': 'CHILE', 'code': '56'},
        {'value': '43', 'key': 'CHINA', 'code': '86'},
        {'value': '44', 'key': 'CHRISTMAS ISLAND', 'code': '61'},
        {'value': '45', 'key': 'COCOS (KEELING) ISLANDS', 'code': '672'},
        {'value': '46', 'key': 'COLOMBIA', 'code': '57'},
        {'value': '47', 'key': 'COMOROS', 'code': '269'},
        {'value': '48', 'key': 'CONGO', 'code': '242'},
        {'value': '49', 'key': 'CONGO, THE DEMOCRATIC REPUBLIC OF THE', 'code': '242'},
        {'value': '50', 'key': 'COOK ISLANDS', 'code': '682'},
        {'value': '51', 'key': 'COSTA RICA', 'code': '506'},
        {'value': '52', 'key': 'COTE DIVOIRE', 'code': '225'},
        {'value': '53', 'key': 'CROATIA', 'code': '385'},
        {'value': '54', 'key': 'CUBA', 'code': '53'},
        {'value': '55', 'key': 'CYPRUS', 'code': '357'},
        {'value': '56', 'key': 'CZECH REPUBLIC', 'code': '420'},
        {'value': '57', 'key': 'DENMARK', 'code': '45'},
        {'value': '58', 'key': 'DJIBOUTI', 'code': '253'},
        {'value': '59', 'key': 'DOMINICA', 'code': '1767'},
        {'value': '60', 'key': 'DOMINICAN REPUBLIC', 'code': '1809'},
        {'value': '61', 'key': 'ECUADOR', 'code': '593'},
        {'value': '62', 'key': 'EGYPT', 'code': '20'},
        {'value': '63', 'key': 'EL SALVADOR', 'code': '503'},
        {'value': '64', 'key': 'EQUATORIAL GUINEA', 'code': '240'},
        {'value': '65', 'key': 'ERITREA', 'code': '291'},
        {'value': '66', 'key': 'ESTONIA', 'code': '372'},
        {'value': '67', 'key': 'ETHIOPIA', 'code': '251'},
        {'value': '68', 'key': 'FALKLAND ISLANDS (MALVINAS)', 'code': '500'},
        {'value': '69', 'key': 'FAROE ISLANDS', 'code': '298'},
        {'value': '70', 'key': 'FIJI', 'code': '679'},
        {'value': '71', 'key': 'FINLAND', 'code': '358'},
        {'value': '72', 'key': 'FRANCE', 'code': '33'},
        {'value': '73', 'key': 'FRENCH GUIANA', 'code': '594'},
        {'value': '74', 'key': 'FRENCH POLYNESIA', 'code': '689'},
        {'value': '75', 'key': 'FRENCH SOUTHERN TERRITORIES', 'code': '0'},
        {'value': '76', 'key': 'GABON', 'code': '241'},
        {'value': '77', 'key': 'GAMBIA', 'code': '220'},
        {'value': '78', 'key': 'GEORGIA', 'code': '995'},
        {'value': '79', 'key': 'GERMANY', 'code': '49'},
        {'value': '80', 'key': 'GHANA', 'code': '233'},
        {'value': '81', 'key': 'GIBRALTAR', 'code': '350'},
        {'value': '82', 'key': 'GREECE', 'code': '30'},
        {'value': '83', 'key': 'GREENLAND', 'code': '299'},
        {'value': '84', 'key': 'GRENADA', 'code': '1473'},
        {'value': '85', 'key': 'GUADELOUPE', 'code': '590'},
        {'value': '86', 'key': 'GUAM', 'code': '1671'},
        {'value': '87', 'key': 'GUATEMALA', 'code': '502'},
        {'value': '88', 'key': 'GUINEA', 'code': '224'},
        {'value': '89', 'key': 'GUINEA-BISSAU', 'code': '245'},
        {'value': '90', 'key': 'GUYANA', 'code': '592'},
        {'value': '91', 'key': 'HAITI', 'code': '509'},
        {'value': '92', 'key': 'HEARD ISLAND AND MCDONALD ISLANDS', 'code': '0'},
        {'value': '93', 'key': 'HOLY SEE (VATICAN CITY STATE)', 'code': '39'},
        {'value': '94', 'key': 'HONDURAS', 'code': '504'},
        {'value': '95', 'key': 'HONG KONG', 'code': '852'},
        {'value': '96', 'key': 'HUNGARY', 'code': '36'},
        {'value': '97', 'key': 'ICELAND', 'code': '354'},
        {'value': '98', 'key': 'INDIA', 'code': '91'},
        {'value': '99', 'key': 'INDONESIA', 'code': '62'},
        {'value': '100', 'key': 'IRAN, ISLAMIC REPUBLIC OF', 'code': '98'},
        {'value': '101', 'key': 'IRAQ', 'code': '964'},
        {'value': '102', 'key': 'IRELAND', 'code': '353'},
        {'value': '103', 'key': 'ISRAEL', 'code': '972'},
        {'value': '104', 'key': 'ITALY', 'code': '39'},
        {'value': '105', 'key': 'JAMAICA', 'code': '1876'},
        {'value': '106', 'key': 'JAPAN', 'code': '81'},
        {'value': '107', 'key': 'JORDAN', 'code': '962'},
        {'value': '108', 'key': 'KAZAKHSTAN', 'code': '7'},
        {'value': '109', 'key': 'KENYA', 'code': '254'},
        {'value': '110', 'key': 'KIRIBATI', 'code': '686'},
        {'value': '111', 'key': 'KOREA, DEMOCRATIC PEOPLES REPUBLIC OF', 'code': '850'},
        {'value': '112', 'key': 'KOREA, REPUBLIC OF', 'code': '82'},
        {'value': '113', 'key': 'KUWAIT', 'code': '965'},
        {'value': '114', 'key': 'KYRGYZSTAN', 'code': '996'},
        {'value': '115', 'key': 'LAO PEOPLES DEMOCRATIC REPUBLIC', 'code': '856'},
        {'value': '116', 'key': 'LATVIA', 'code': '371'},
        {'value': '117', 'key': 'LEBANON', 'code': '961'},
        {'value': '118', 'key': 'LESOTHO', 'code': '266'},
        {'value': '119', 'key': 'LIBERIA', 'code': '231'},
        {'value': '120', 'key': 'LIBYAN ARAB JAMAHIRIYA', 'code': '218'},
        {'value': '121', 'key': 'LIECHTENSTEIN', 'code': '423'},
        {'value': '122', 'key': 'LITHUANIA', 'code': '370'},
        {'value': '123', 'key': 'LUXEMBOURG', 'code': '352'},
        {'value': '124', 'key': 'MACAO', 'code': '853'},
        {'value': '125', 'key': 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF', 'code': '389'},
        {'value': '126', 'key': 'MADAGASCAR', 'code': '261'},
        {'value': '127', 'key': 'MALAWI', 'code': '265'},
        {'value': '128', 'key': 'MALAYSIA', 'code': '60'},
        {'value': '129', 'key': 'MALDIVES', 'code': '960'},
        {'value': '130', 'key': 'MALI', 'code': '223'},
        {'value': '131', 'key': 'MALTA', 'code': '356'},
        {'value': '132', 'key': 'MARSHALL ISLANDS', 'code': '692'},
        {'value': '133', 'key': 'MARTINIQUE', 'code': '596'},
        {'value': '134', 'key': 'MAURITANIA', 'code': '222'},
        {'value': '135', 'key': 'MAURITIUS', 'code': '230'},
        {'value': '136', 'key': 'MAYOTTE', 'code': '269'},
        {'value': '137', 'key': 'MEXICO', 'code': '52'},
        {'value': '138', 'key': 'MICRONESIA, FEDERATED STATES OF', 'code': '691'},
        {'value': '139', 'key': 'MOLDOVA, REPUBLIC OF', 'code': '373'},
        {'value': '140', 'key': 'MONACO', 'code': '377'},
        {'value': '141', 'key': 'MONGOLIA', 'code': '976'},
        {'value': '142', 'key': 'MONTSERRAT', 'code': '1664'},
        {'value': '143', 'key': 'MOROCCO', 'code': '212'},
        {'value': '144', 'key': 'MOZAMBIQUE', 'code': '258'},
        {'value': '145', 'key': 'MYANMAR', 'code': '95'},
        {'value': '146', 'key': 'NAMIBIA', 'code': '264'},
        {'value': '147', 'key': 'NAURU', 'code': '674'},
        {'value': '148', 'key': 'NEPAL', 'code': '977'},
        {'value': '149', 'key': 'NETHERLANDS', 'code': '31'},
        {'value': '150', 'key': 'NETHERLANDS ANTILLES', 'code': '599'},
        {'value': '151', 'key': 'NEW CALEDONIA', 'code': '687'},
        {'value': '152', 'key': 'NEW ZEALAND', 'code': '64'},
        {'value': '153', 'key': 'NICARAGUA', 'code': '505'},
        {'value': '154', 'key': 'NIGER', 'code': '227'},
        {'value': '155', 'key': 'NIGERIA', 'code': '234'},
        {'value': '156', 'key': 'NIUE', 'code': '683'},
        {'value': '157', 'key': 'NORFOLK ISLAND', 'code': '672'},
        {'value': '158', 'key': 'NORTHERN MARIANA ISLANDS', 'code': '1670'},
        {'value': '159', 'key': 'NORWAY', 'code': '47'},
        {'value': '160', 'key': 'OMAN', 'code': '968'},
        {'value': '161', 'key': 'PAKISTAN', 'code': '92'},
        {'value': '162', 'key': 'PALAU', 'code': '680'},
        {'value': '163', 'key': 'PALESTINIAN TERRITORY, OCCUPIED', 'code': '970'},
        {'value': '164', 'key': 'PANAMA', 'code': '507'},
        {'value': '165', 'key': 'PAPUA NEW GUINEA', 'code': '675'},
        {'value': '166', 'key': 'PARAGUAY', 'code': '595'},
        {'value': '167', 'key': 'PERU', 'code': '51'},
        {'value': '168', 'key': 'PHILIPPINES', 'code': '63'},
        {'value': '169', 'key': 'PITCAIRN', 'code': '0'},
        {'value': '170', 'key': 'POLAND', 'code': '48'},
        {'value': '171', 'key': 'PORTUGAL', 'code': '351'},
        {'value': '172', 'key': 'PUERTO RICO', 'code': '1787'},
        {'value': '173', 'key': 'QATAR', 'code': '974'},
        {'value': '174', 'key': 'REUNION', 'code': '262'},
        {'value': '175', 'key': 'ROMANIA', 'code': '40'},
        {'value': '176', 'key': 'RUSSIAN FEDERATION', 'code': '70'},
        {'value': '177', 'key': 'RWANDA', 'code': '250'},
        {'value': '178', 'key': 'SAINT HELENA', 'code': '290'},
        {'value': '179', 'key': 'SAINT KITTS AND NEVIS', 'code': '1869'},
        {'value': '180', 'key': 'SAINT LUCIA', 'code': '1758'},
        {'value': '181', 'key': 'SAINT PIERRE AND MIQUELON', 'code': '508'},
        {'value': '182', 'key': 'SAINT VINCENT AND THE GRENADINES', 'code': '1784'},
        {'value': '183', 'key': 'SAMOA', 'code': '684'},
        {'value': '184', 'key': 'SAN MARINO', 'code': '378'},
        {'value': '185', 'key': 'SAO TOME AND PRINCIPE', 'code': '239'},
        {'value': '186', 'key': 'SAUDI ARABIA', 'code': '966'},
        {'value': '187', 'key': 'SENEGAL', 'code': '221'},
        {'value': '188', 'key': 'SERBIA AND MONTENEGRO', 'code': '381'},
        {'value': '189', 'key': 'SEYCHELLES', 'code': '248'},
        {'value': '190', 'key': 'SIERRA LEONE', 'code': '232'},
        {'value': '191', 'key': 'SINGAPORE', 'code': '65'},
        {'value': '192', 'key': 'SLOVAKIA', 'code': '421'},
        {'value': '193', 'key': 'SLOVENIA', 'code': '386'},
        {'value': '194', 'key': 'SOLOMON ISLANDS', 'code': '677'},
        {'value': '195', 'key': 'SOMALIA', 'code': '252'},
        {'value': '196', 'key': 'SOUTH AFRICA', 'code': '27'},
        {'value': '197', 'key': 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS', 'code': '0'},
        {'value': '198', 'key': 'SPAIN', 'code': '34'},
        {'value': '199', 'key': 'SRI LANKA', 'code': '94'},
        {'value': '200', 'key': 'SUDAN', 'code': '249'},
        {'value': '201', 'key': 'SURINAME', 'code': '597'},
        {'value': '202', 'key': 'SVALBARD AND JAN MAYEN', 'code': '47'},
        {'value': '203', 'key': 'SWAZILAND', 'code': '268'},
        {'value': '204', 'key': 'SWEDEN', 'code': '46'},
        {'value': '205', 'key': 'SWITZERLAND', 'code': '41'},
        {'value': '206', 'key': 'SYRIAN ARAB REPUBLIC', 'code': '963'},
        {'value': '207', 'key': 'TAIWAN, PROVINCE OF CHINA', 'code': '886'},
        {'value': '208', 'key': 'TAJIKISTAN', 'code': '992'},
        {'value': '209', 'key': 'TANZANIA, UNITED REPUBLIC OF', 'code': '255'},
        {'value': '210', 'key': 'THAILAND', 'code': '66'},
        {'value': '211', 'key': 'TIMOR-LESTE', 'code': '670'},
        {'value': '212', 'key': 'TOGO', 'code': '228'},
        {'value': '213', 'key': 'TOKELAU', 'code': '690'},
        {'value': '214', 'key': 'TONGA', 'code': '676'},
        {'value': '215', 'key': 'TRINIDAD AND TOBAGO', 'code': '1868'},
        {'value': '216', 'key': 'TUNISIA', 'code': '216'},
        {'value': '217', 'key': 'TURKEY', 'code': '90'},
        {'value': '218', 'key': 'TURKMENISTAN', 'code': '7370'},
        {'value': '219', 'key': 'TURKS AND CAICOS ISLANDS', 'code': '1649'},
        {'value': '220', 'key': 'TUVALU', 'code': '688'},
        {'value': '221', 'key': 'UGANDA', 'code': '256'},
        {'value': '222', 'key': 'UKRAINE', 'code': '380'},
        {'value': '223', 'key': 'UNITED ARAB EMIRATES', 'code': '971'},
        {'value': '224', 'key': 'UNITED KINGDOM', 'code': '44'},
        {'value': '225', 'key': 'UNITED STATES', 'code': '1'},
        {'value': '226', 'key': 'UNITED STATES MINOR OUTLYING ISLANDS', 'code': '1'},
        {'value': '227', 'key': 'URUGUAY', 'code': '598'},
        {'value': '228', 'key': 'UZBEKISTAN', 'code': '998'},
        {'value': '229', 'key': 'VANUATU', 'code': '678'},
        {'value': '230', 'key': 'VENEZUELA', 'code': '58'},
        {'value': '231', 'key': 'VIET NAM', 'code': '84'},
        {'value': '232', 'key': 'VIRGIN ISLANDS, BRITISH', 'code': '1284'},
        {'value': '233', 'key': 'VIRGIN ISLANDS, U.S.', 'code': '1340'},
        {'value': '234', 'key': 'WALLIS AND FUTUNA', 'code': '681'},
        {'value': '235', 'key': 'WESTERN SAHARA', 'code': '212'},
        {'value': '236', 'key': 'YEMEN', 'code': '967'},
        {'value': '237', 'key': 'ZAMBIA', 'code': '260'},
        {'value': '238', 'key': 'ZIMBABWE', 'code': '263'}
    ];
    return countries;
}

export const blankImgInput = (event) => {
    console.log("event",event)
    const element = event.target
    element.value = ''
}
