import React, { useEffect , useState} from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const TenantSidebar = () => {
	const selectedMall = useSelector(state => state?.mallsStore?.selectedMall);
	const profileRes = useSelector(state => state?.ProfileRes?.profileRes);
	const [retailerURL, setRetailerURL] = useState(null);

	const checkModuleAccess = (module) => {
		let hasAccess = false;
		profileRes?.modules?.forEach(item =>{
            if(item && item?.base_url === module){
                hasAccess = true;
            }
        });
		return hasAccess;
	};

	useEffect(()=>{
		if(profileRes && profileRes?.retailer_link){
			//let r = 'http://eserviceupgrade.emaarmalls.com/login?tmssystmp=' + profileRes?.retailer_link;
			let r = 'https://retailservices.emaarmalls.com/login?tmssystmp=' + profileRes?.retailer_link
			setRetailerURL(r);
		}
	}, [profileRes])
	
	return (
		<aside className="main-sidebar sidebar-dark-primary">
            <Link to="/" className="brand-link mt-2">
                <img src="/dist/img/logoemar.svg" alt="Logo" className="" />
            </Link>
            <div className="sidebar">
                <nav className="mt-4">
                  <ul className={selectedMall ? 'nav nav-pills nav-sidebar flex-column' : 'disp-none'} >
                    <li className="nav-item">
						<Link to="/" className="nav-link pl-0">
							<img src="/dist/img/dash.svg" alt="Logo" className="mr-3" />Dashboard
						</Link>
					</li>
					{
						checkModuleAccess('/retailer') && retailerURL && 
						(
							<li className="nav-item">
							<a href={retailerURL} className="nav-link pl-0" target={'_blank'}>
								<img src="/dist/img/offersin.svg" alt="Logo" className="mr-3" />Retailer Eservices
							</a>
							</li>
						)
					}

					{
						(checkModuleAccess('/offers') || checkModuleAccess('/events')) && 
						(
							<li className="nav-item">
								<a className="nav-link pl-0" href="#marketingMenu" data-toggle="collapse" data-target="#marketingMenu">
									<img src="/dist/img/campaigns-events.svg" alt="Logo" className="mr-3" aria-expanded="true" />Marketing
								</a>
								<div className="collapse show" id="marketingMenu" aria-expanded="false">
									<ul className="flex-column pl-4 nav">
									{checkModuleAccess('/offers') && (<li className="nav-item mb-2"><Link className="nav-link py-0" to="/offers"><span> - Offers</span></Link></li>)}
									{checkModuleAccess('/events') && (<li className="nav-item mb-2"><Link className="nav-link py-0" to="/events"><span> - Events</span></Link></li>)}
									</ul>
								</div>
							</li>
						)
					}
					{
						(checkModuleAccess('/stores') || checkModuleAccess('/products') || checkModuleAccess('/users')) && 
						(
							<li className="nav-item">
								<a className="nav-link pl-0" href="#storeMenu" data-toggle="collapse" data-target="#storeMenu">
									<img src="/dist/img/store.svg" alt="Logo" className="mr-3" aria-expanded="true" />My Store
								</a>
								<div className="collapse show" id="storeMenu" aria-expanded="false">
									<ul className="flex-column pl-4 nav">
									{checkModuleAccess('/stores') && (<li className="nav-item mb-2"><Link className="nav-link py-0" to="/stores"><span> - Stores</span></Link></li>)}
									{checkModuleAccess('/products') && (<li className="nav-item mb-2"><Link className="nav-link py-0" to="/products"><span> - Products</span></Link></li>)}
									{checkModuleAccess('/users') && (<li className="nav-item mb-2"><Link className="nav-link py-0" to="/users"><span> - Users</span></Link></li>)}
									</ul>
								</div>
							</li>
						)
					}
					{
						checkModuleAccess('/applications') && 
						(
							<li className="nav-item">
								<Link to="/applications" className="nav-link pl-0">
									<img src="/dist/img/store.svg" alt="Logo" className="mr-3" />My Applications
								</Link>
							</li>
							)
					}
					{
						checkModuleAccess('/tickets') && 
						(
							<li className="nav-item">
								<Link to="/tickets" className="nav-link pl-0">
									<img src="/dist/img/store.svg" alt="Logo" className="mr-3" />My Tickets
								</Link>
							</li>
							)
					}	
                  </ul>
                </nav>
              </div>
        </aside>
	);
}
 
export default TenantSidebar;