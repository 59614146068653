import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";
import { getToken } from "../../config";

const iniState = { isLoading: false, isError: "",offersList:null, createRespose: null, getRespose: null,  deleteRespose: null, uploadRespose: null, commonData: null, progress: 0, isDraftSave: null , updateOfferRes:null , submitFormSuccess:null};

const offerSlice = createSlice({
    name: "createOffer",
    initialState: iniState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
			state.isDraftSave = false;
			state.deleteRespose = null;
            state.submitFormSuccess=false;
            state.getRespose=null;
        },
        apiCallSuccess(state,action){
            state.isLoading = false;
            state.offersList=action.payload;
        },
		apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        createOfferSuccess(state, action) {
            state.isLoading = false;
            state.createRespose = action.payload;
            state.submitFormSuccess=true;

        },
        updateDraftStatus(state) {
            state.isDraftSave = true;
        },
        getOfferSuccess(state, action) {
            state.isLoading = false;
            state.getRespose = action.payload;
        },
        updateOfferSuccess(state,action){
            state.isLoading=false;
            state.updateOfferRes=action.payload;
        },
        deleteOfferSuccess(state, action) {
            state.isLoading = false;
            state.deleteRespose = action.payload;
        },
		commonApiData(state, action) {
			state.isLoading = false;
            state.commonData = action.payload;
        },
		uploadApiSuccess(state, action) {
			state.isLoading = false;
            state.uploadRespose = action.payload;
        },
		updateProgress(state, action) {
            state.progress = action.payload;
        },
        formSubmittedSuccess(state,action){
            state.isLoading=false;
            state.submitFormSuccess=true;
        },
        emptyState(state){
            state.isDraftSave = false;
			state.deleteRespose = null;
            state.submitFormSuccess=false;
        },
        resetList(state){
            state.offersList = null;
        }
    },
}); 

export const getOffersApi = () => {
    return async (dispatch,getState) => {
        dispatch(offerActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        const selectedMall = getState()?.mallsStore?.selectedMall;

        try {
            const res = await axios({
					method: "GET",
					url: `/mds/offers/list?mid=` + selectedMall,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
            const { data  } = res?.data;
			if(data?.success){
                dispatch(offerActions.apiCallSuccess(data?.data));
            }else{
                throw "something went Wrong"
            }
        } catch (error) {
            dispatch(offerActions.apiCallFailed(error));
            console.log(error)
        }
    }
};



export const createOfferAPI = (formData) => {
    return async (dispatch) => {
        dispatch(offerActions.apiCallRequested());
        try {
            const res = await axios({
					method: "POST",
					url: `/mds/offers/add`,
					data: formData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
                console.log("res",res)
            if(res?.data?.data?.success){
                const { data  } = res?.data; 
                dispatch(offerActions.createOfferSuccess(data));
				if(formData.saveMethod === 'save'){
                    dispatch(offerActions.updateDraftStatus());
                    ToastSuccess(data?.message)
                    dispatch(getOffersApi())
                    
				}else{
                    dispatch(offerActions.formSubmittedSuccess());
                    dispatch(getOffersApi())
                    ToastSuccess(res?.data?.message)
                }
            }else{
                throw "someThing wrong !"
            }
        } catch (error) {
            dispatch(offerActions.apiCallFailed(error));
            console.log(error)
        }
    }
};
export const updateOfferAPI = (formData,id) => {
    return async (dispatch) => {
        dispatch(offerActions.apiCallRequested());
        try {
            const res = await axios({
					method: "POST",
					url: `/mds/offers/update/${id}`,
					data: formData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
                console.log("res",res)
            if(res?.data?.data?.success){
                const { data } = res?.data; 
                // dispatch(offerActions.updateOfferSuccess(data));
				if(formData.saveMethod === 'save'){
                    dispatch(offerActions.updateDraftStatus());
                    ToastSuccess(res?.data?.data?.message)
                    dispatch(getOffersApi())
				}else{
                    dispatch(offerActions.formSubmittedSuccess());
                    dispatch(getOffersApi())
                    ToastSuccess(res?.data?.data?.message)
                }
            }else{
                throw "someThing wrong !"
            }
        } catch (error) {
            dispatch(offerActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getOfferAPI = (offerId) => {
    return async (dispatch) => {
        dispatch(offerActions.apiCallRequested());
        try {
            const res = await axios({
					method: "GET",
					url: `/mds/offers/get/${offerId}`,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.data?.success){
                const { data } = res?.data; 
                console.log("datatata",data)
                dispatch(offerActions.getOfferSuccess(data?.data));
            }else{
                throw "someThing wrong !";
            }
        } catch (error) {
            dispatch(offerActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const commonAPI = () => {
    return async (dispatch, getState) => {
        dispatch(offerActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        const selectedMall = getState()?.mallsStore?.selectedMall;
        try {
            const res = await axios({
					method: "GET",
					url: `/mds/metadata/list?mid=` + selectedMall,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
            const { data } = res?.data;
			console.log(data);
            dispatch(offerActions.commonApiData(data));
        } catch (error) {
            dispatch(offerActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const uploadAPI = (fileData) => {
    return async (dispatch) => {
        dispatch(offerActions.apiCallRequested());
        try {
            const res = await axios({
					method: "POST",
					url: `/tms/upload`,
					data: fileData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
            const { data } = res?.data;
			console.log(data);
            dispatch(offerActions.uploadApiSuccess(data));
        } catch (error) {
            dispatch(offerActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const deleteOfferApi = (data) => {
    return async (dispatch, getState) => {
		const bodyData = { "itemToDelete": data };
        const token = getState()?.LoginRes?.authResToken;
        dispatch(offerActions.apiCallRequested());
        try {
            const res = await axios({
                method: "POST",
				data: bodyData,
				url: `/mds/offers/delete`, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { data } = res?.data;
			console.log(data?.success);
            if (data?.success === true) {
                dispatch(offerActions.deleteOfferSuccess(data?.message));
				console.log('here');
				//ToastSuccess(message);
            } else {
				console.log('here 2');
                throw data?.message;
            }
        } catch (error) {
            dispatch(offerActions.apiCallFailed(error));
            console.log(error)
        }
    }
};



export const offerActions = offerSlice.actions;

export default offerSlice.reducer;
