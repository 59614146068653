import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastSuccess, ToastError } from "../../common/Toast";
import { getToken } from "../../config";

const iniState = { isLoading: false,  isLoadingApp: false, isError: null, createRespose: null, saveRespose: null, listRespose: null, listAppRespose: null, getRespose: null, updateRespose: null , companyName:null,brands:null,categories:null,subCategories:null, malls:null, pendingAppList: null
};

const appSlice = createSlice({
    name: "applications",
    initialState: iniState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
        },
        apiCallRequestedApp(state) {
            state.isLoadingApp = true;
        },
        apiResetValues(state) {
            state.createRespose = null;
            state.saveRespose = null;
            state.updateRespose = null;
            state.getRespose = null;
            state.isLoading = false;
        },
		apiCallFailed(state, action) {
            state.isLoading = false;
            if(action)
                state.isError = action.payload;
        },
        createAppSuccess(state, action) {
            state.isLoading = false;
            state.createRespose = action.payload;
        },
        saveAppSuccess(state, action) {
            state.isLoading = false;
            state.saveRespose = action.payload;
        },
        updateAppSuccess(state, action) {
            state.isLoading = false;
            state.updateRespose = action.payload;
        },
        getAppSuccess(state, action) {
            state.isLoading = false;
            state.isLoadingApp = false;
            state.getRespose = action.payload;
        },
        listAppSuccess(state, action) {
            state.isLoading = false;
            state.listRespose = action.payload;
        },

        listNewAppSuccess(state, action) {
            state.isLoadingApp = false;
            state.listAppRespose = action.payload.data;
            state.pendingAppList = action.payload.pending;
        },
        companyNameAndBrandsSuccess(state,action){
            console.log("action.payload",action.payload)
            state.isLoading = false;
            state.companyName = action.payload.customers;
            state.brands= action.payload.brands;
            state.categories = action.payload.categories;
            state.subCategories = action.payload.subCategories;
            state.malls = action.payload.malls;
        }
    },
});

export const createApplicationAPI = (formData) => {
    return async (dispatch) => {
        dispatch(appActions.apiResetValues());
        dispatch(appActions.apiCallRequested());
        try {
            const res = await axios({
                    
					method: "POST",
					url: `/baw/add`,
					data: formData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
            if(res?.data?.error === false){ 
                dispatch(appActions.createAppSuccess(res.data));
                ToastSuccess(res.data.message);
                //dispatch(getApplicationListAPI());
                //dispatch(listApplicationAPI());
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, please try again later!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const saveApplicationAPI = (formData) => {
    return async (dispatch) => {
        dispatch(appActions.apiResetValues());
        dispatch(appActions.apiCallRequested());
        try {
            const res = await axios({
					
                    method: "POST",
					url: `/baw/save`,
					data: formData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.error === false){
                dispatch(appActions.saveAppSuccess(res.data));
                ToastSuccess(res.data.message);
                //dispatch(getApplicationListAPI());
                //dispatch(listApplicationAPI());
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, please try again later!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const updateApplicationAPI = (formData) => {
    return async (dispatch) => {
        dispatch(appActions.apiResetValues());
        dispatch(appActions.apiCallRequested());
        try {
            const res = await axios({
					
                    method: "POST",
					url: `/baw/update`,
					data: formData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.error === false){
                dispatch(appActions.updateAppSuccess(res.data));
                ToastSuccess(res.data.message);
                //dispatch(getApplicationListAPI());
                //dispatch(listApplicationAPI());
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, please try again later!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const updateApplicationAPIOffer = (formData) => {
    return async (dispatch) => {
        dispatch(appActions.apiResetValues());
        dispatch(appActions.apiCallRequested());
        try {
            const res = await axios({
					
                    method: "POST",
					url: `/baw/update`,
					data: formData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.error === false){
                dispatch(appActions.updateAppSuccess(res.data));
                //ToastSuccess(res.data.message);
                //dispatch(getApplicationListAPI());
                //dispatch(listApplicationAPI());
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, please try again later!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getApplicationAPI = (appId) => {
    return async (dispatch) => {
        dispatch(appActions.apiResetValues());
        dispatch(appActions.apiCallRequested());
        dispatch(appActions.apiCallRequestedApp());
        try {
            const res = await axios({
					
                    method: "GET",
					url: `/baw/get/${appId}`,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.error === false){
                const { data } = res?.data;
                dispatch(appActions.getAppSuccess(data?.data?.data));
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, please try again later!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const listApplicationAPI = () => {
    return async (dispatch) => {
        dispatch(appActions.apiCallRequested());
        try {
            const res = await axios({
					
                    method: "GET",
					url: `/baw/list`,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.error === false){
                const { data } = res?.data; 
                dispatch(appActions.listAppSuccess(data));
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, please refresh the page!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getApplicationListAPI = () => {
    return async (dispatch) => {
        dispatch(appActions.apiCallRequestedApp());
        try {
            const res = await axios({
                    method: "GET",
					url: `/baw/app-list`,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.error === false){
                const { data } = res?.data; 
                dispatch(appActions.listNewAppSuccess(res?.data));
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, please refresh the page!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const deleteApplicationAPI = (formData) => {
    return async (dispatch) => {
        dispatch(appActions.apiCallRequested());
        try {
            const res = await axios({
					
                    method: "POST",
					url: `/baw/delete`,
					data: formData,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            if(res?.data?.error === false){
                dispatch(getApplicationListAPI());
                dispatch(listApplicationAPI());
                ToastSuccess(res.data.message);
            }else if(res?.data?.message){
                ToastError(res.data.message);
                dispatch(appActions.apiCallFailed(null));
            }else{
                ToastError("Unable to connect to API, Please try again!");
                dispatch(appActions.apiCallFailed(null));
            }
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
        }
    }
};


export const dropDownsAPi = () => {
    return async (dispatch) => {
        dispatch(appActions.apiCallRequested());
        try {
            const res = await axios({
					method: "GET",
					url: `/baw/application-metadata`,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
                
            console.log("res of data",res.data)
            if(res?.data?.error === false){
                dispatch(appActions.companyNameAndBrandsSuccess(res?.data?.data));
            }else{
                throw res?.data?.data?.message
            }
            
        } catch (error) {
            dispatch(appActions.apiCallFailed(error));
        }
    }
};

export const appActions = appSlice.actions;

export default appSlice.reducer;
