import { useSelector } from 'react-redux';
import { PropagateLoader } from "react-spinners";
import React, { Fragment, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "../common/components/ScrollToTop";
const Navbar= lazy(()=>import("../views/general/navbar"));

const Login = lazy(() => import("../views/auth/login"));
const Register = lazy(()=>import( "../views/auth/register"));
const NotFound = lazy(()=>import( "../views/general/notfound"));
const VerifyEmail = lazy(()=>import('../views/auth/VerifyEmail'));
const ResetPassword = lazy(()=>import("../views/auth/ResetPassword"));
const ForgetPassword = lazy(()=>import("../views/auth/ForgetPassword"));
const PrivacyAndTerms = lazy(()=>import("../views/general/PrivacyAndTerms"));

const Home = lazy(()=>import("../views/home"));
const Profile = lazy(()=>import("../views/auth/Profile"));
const Setings = lazy(()=>import("../Pages/settings/Settings"));
const Benefits = lazy(()=>import("../Pages/benefits/Benefits"));
const TermsAndConditions = lazy(()=>import("../Pages/privacyTerms/TermsAndConditons"));

const Faqs = lazy(()=>import("../views/general/faqs"));

const Preview = lazy(()=>import("../views/preview/Preview"));

const Users = lazy(()=>import("../views/users/Users"));
const CreateUser = lazy(()=>import("../views/users/CreateUser"));

const Stores= lazy(()=>import("../views/store/Stores"));
const UpdateStore = lazy(()=>import( "../views/store/UpdateStore"));

const Offers = lazy(()=>import( "../views/offers/Offers"));
const CreateOffer = lazy(()=>import("../views/offers/create"));
const UpdateOffer = lazy(()=>import( "../views/offers/update"));

const Events = lazy(()=>import( "../views/events/Events"));
const CreateEvent = lazy(()=>import("../views/events/CreateEvent"));
const UpdateEvent = lazy(()=>import("../views/events/UpdateEvent"));

const Products = lazy(()=>import("../views/products/Products"));
const UpdateProduct = lazy(()=>import("../views/products/UpdateProduct"));
const CreateProduct = lazy(()=>import( "../views/products/CreateProduct"));

const OfflineEvents  = lazy(()=>import("../views/offline-events/OfflineEvents"));
const OfflineEventsList  = lazy(()=>import("../views/offline-events/OfflineEventsList"));

const Tickets = lazy(()=>import("../views/Tickets/Tickets"));
const CreateTickets = lazy(()=>import("../views/Tickets/CreateTickets"));

const ViewApplication = lazy(()=>import("../views/leads/ViewNew"));
const ListApplications = lazy(()=>import( "../views/leads/ListNew"));
const UpdateApplication = lazy(()=>import("../views/leads/UpdateNew"));
const CreateApplication = lazy(()=>import( "../views/leads/CreateNew"));
const ReviewApplication = lazy(()=>import( "../views/leads/Review"));
const NotificationForm = lazy(()=>import("../views/leads/NotificationForm"));

const Routes = () => {
	const isLogin = useSelector(state => state.LoginRes.authResToken);
  const SuspenseLoading = () => {
      return (
        <Fragment>
          <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
            <div className="d-flex align-items-center flex-column px-4">
              <PropagateLoader color={"#5383ff"} loading={true} />
            </div>
          </div>
        </Fragment>
      );
    };

    return (
        <Suspense fallback={<SuspenseLoading />}>
          <Router basename='/'>
            <ScrollToTop />
              <div className={"App " + (isLogin ? 'sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed' : '')}>
                <div className="wrapper">
                  <div className="content">
                      <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/forget-password" component={ForgetPassword} />
                        <Route exact path="/password-reset/:id" component={ResetPassword} />
                        <Route exact path="/verify-email/:id" component={VerifyEmail} />
                        <Route exact path="/privacy-policy" component={PrivacyAndTerms} />
                        <Route exact path="/terms-and-conditions" component={PrivacyAndTerms} />
                        <Route exact path="/faqs" component={Faqs} />

                        <ProtectedRoute exact path="/" component={Home} />
                        <ProtectedRoute exact path="/profile" component={Profile} />

                        <ProtectedRoute exact path="/page/settings" component={Setings} />
                        <ProtectedRoute exact path="/page/benefits" component={Benefits} />
                        <ProtectedRoute exact path="/page/privacy-policy" component={TermsAndConditions} />
                        <ProtectedRoute exact path="/page/terms-and-conditions" component={TermsAndConditions} />

                        <ProtectedRoute exact path="/users" component={Users} />
                        <ProtectedRoute exact path="/users/create" component={CreateUser} />
                        <ProtectedRoute exact path="/users/update/:id" component={CreateUser} />

                        <ProtectedRoute exact path="/stores" component={Stores} />
                        <ProtectedRoute exact path="/stores/update/:id" component={UpdateStore} />

                        <ProtectedRoute exact path="/offers" component={Offers} />
                        <ProtectedRoute exact path="/offers/create" component={CreateOffer} />
                        <ProtectedRoute exact path="/offers/view/:id" component={Preview} />
            						<ProtectedRoute exact path="/offers/update/:id" component={UpdateOffer} />
                        
                        <ProtectedRoute exact path="/events" component={Events} />
                        <ProtectedRoute exact path="/events/create" component={CreateEvent} />
                        <ProtectedRoute exact path="/events/view/:id" component={Preview} />
                        <ProtectedRoute exact path="/events/update/:id" component={UpdateEvent} />

                        <ProtectedRoute exact path="/products" component={Products} />
                        <ProtectedRoute exact path="/products/view/:id" component={Preview} />
                        <ProtectedRoute exact path="/products/create" component={CreateProduct} />
                        <ProtectedRoute exact path="/products/update/:id" component={UpdateProduct} />
                        
                        <ProtectedRoute exact path="/offline-events" component={OfflineEventsList} />
                        <ProtectedRoute exact path="/offline-events/create" component={OfflineEvents} />
                        
                        <ProtectedRoute exact path="/tickets" component={Tickets} />
                        <ProtectedRoute exact path="/tickets/create" component={CreateTickets} />
                        <ProtectedRoute exact path="/tickets/update/:id" component={CreateTickets} />
                        <ProtectedRoute exact path="/tickets/view/:id" component={Preview} />
                        
                        <ProtectedRoute exact path="/applications" component={ListApplications} />
                        <ProtectedRoute exact path="/applications/create" component={CreateApplication} />
                        <ProtectedRoute exact path="/applications/view/:id" component={ViewApplication} />
                        <ProtectedRoute exact path="/applications/update/:id/:taskId" component={UpdateApplication} />
                        <ProtectedRoute exact path="/applications/review/:id/:taskId" component={ReviewApplication} />
                        <ProtectedRoute exact path="/applications/rfi/:id" component={NotificationForm} />
                        
                        <Route path="*"><NotFound /></Route>
                      </Switch>
                  </div>
                </div>
              </div>
          </Router>
      </Suspense>
    )
};

export default Routes;
