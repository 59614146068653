import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";


/***
 * createUsser Reducer initial state
 * ***/
 const initialUserState = { isLoading: false, isError: "", getUserRes: null ,};


 const getUserSlice = createSlice({
     name:"getUser",
     initialState : initialUserState,

     reducers:{
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
            state.getUserRes =null;
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.getUserRes = action.payload;
            
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
            state.getUserRes =null;
           
        },
        emptyState(state){
            state.getUserRes=null;
        }
     }
 });


 /***
 *  GET USER DETAILS API
 * ***/
export const getUserAPi = (userId) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(getUserActions.apiCallRequested());
        try {
            const res = await axios({
                method: "GET", url: `/users/get/${userId}`, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (!error) {
                const { data } = res?.data;
                dispatch(getUserActions.apiCallSuccess(data?.user));
                // ToastSuccess(data?.info)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(getUserActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getUserActions = getUserSlice.actions;

export default getUserSlice.reducer;


