import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess, ToastError } from "../../common/Toast";
import { UserListApi } from "./users";


/***
 * createUsser Reducer initial state
 * ***/
 const initilCreateUserState = { isLoading: false, isError: "", createUserRes: null ,changeRoute:false};


 const createUserSlice = createSlice({
     name:"createUser",
     initialState : initilCreateUserState,
 
     reducers:{
        PostApiCallRequested(state) {
            state.isLoading = true;
            state.changeRoute=false;
            state.isError = "";
        },
        PostApiCallSuccess(state, action) {
            state.isLoading = false;
            state.createUserRes = action.payload;
            state.changeRoute=true;
        },
        PostApiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
            state.changeRoute=false;
        },
        emptyState(state){
            state.changeRoute=false;
            state.isError = "";
            state.createUserRes=null;
        }
     }
 });


 /***
 * CREATE USER POST API
 * ***/
export const createUserApi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(createUserActions.PostApiCallRequested());
        try {
            const res = await axios({
                method: "POST", url: `/users/add`, data, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (!error) {
                const { data } = res?.data;
                dispatch(UserListApi())
                dispatch(createUserActions.PostApiCallSuccess(data?.info));
                ToastSuccess(data?.info)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(createUserActions.PostApiCallFailed(error));
            ToastError("Oops ! Form not saved. Please review and try again.");
        }
    }
};

export const createUserActions = createUserSlice.actions;

export default createUserSlice.reducer;


