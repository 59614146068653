import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";
import { getToken } from "../../config";


const initialPasswordState = { isLoading: false, isError: "", status: "" , sentMail:false};

const forgotPasswordSlice = createSlice({
    name: "Fogot Password",
    initialState: initialPasswordState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
            state.sentMail= false;
        },
        apiCallSuccess(state, action) {
            state.isLoading = false;
            state.status = action.payload;
            state.sentMail= true;
        },
        apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        stateReset(state){
            state.isLoading = false;
            state.isError = "";
            state.sentMail= false;
        }
    }
});


export const forgotPasswordApi = (data) => {
    return async (dispatch) => {
        dispatch(forgotPasswordActions.apiCallRequested());

        try {
            const res = await axios({
                 method: "POST",
                 url: `/tenant/forget-password`,
                 data,
            });
            console.log("res",res.data)
            // const { error } = res?.data?.data;
            if (!res.data.error) {
                const { message } = res?.data?.data;
                dispatch(forgotPasswordActions.apiCallSuccess(message));
                ToastSuccess(message)
            } else {
                // throw data?.data?.message
                throw res.data
            }
        } catch (error) {
            dispatch(forgotPasswordActions.apiCallFailed(error));
            console.log(error)
        }
    }
};


export const forgotPasswordActions = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;