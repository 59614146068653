import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess } from "../../common/Toast";


const iniState = { isLoading: false, isError: "", eventsList:null ,  progress: 0  ,createEventsRes:null , isDraftSave: null , eventRes:null,updateEventRes:null , deleteRespose:null , submitFormSuccess:null};

const eventsListSlice = createSlice({
    name: "events",
    initialState: iniState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
            state.isError = "";
            state.isDraftSave = false;
            state.submitFormSuccess=false;
            state.eventRes =null;

        },
        apiCallSuccess(state,action){
            state.isLoading = false;
            state.eventsList=action.payload;
        },
		apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        createEventsApiCallSuccess(state,action){
            state.isLoading = false;
            state.createEventsRes=action.payload;
        },
        updateDraftStatus(state) {
            state.isDraftSave = true;
        },
        updateProgress(state, action) {
            state.progress = action.payload;
        },
        getEventByIdSuccess(state,action){
            state.isLoading = false;
            state.eventRes = action.payload;
        },
        updateEventSuccess(state,action){
            state.isLoading=false;
            state.updateEventRes=action.payload
        },
        deleteOfferSuccess(state, action) {
            state.isLoading = false;
            state.deleteRespose = action.payload;
        },
        formSubmittedSuccess(state,action){
            state.isLoading=false;
            state.submitFormSuccess=true;
        },
        resetList(state){
            state.eventsList = null;
        }
    },
}); 




export const getEventsApi = () => {
    return async (dispatch,getState) => {
        dispatch(eventsActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;
        const selectedMall = getState()?.mallsStore?.selectedMall;

        try {
            const res = await axios({
                    method: "GET",
				    url: '/mds/events/list?mid=' + selectedMall,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
            const { data , error } = res?.data;
			if(error === false){
                dispatch(eventsActions.apiCallSuccess(data?.data));
            }else{
                 throw "something went Wrong"
            }
        } catch (error) {
            dispatch(eventsActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const createEventsApi = (formData) => {
    return async (dispatch,getState) => {
        dispatch(eventsActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "POST",
					url: `/mds/events/add`,
                    data: formData,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
           
            console.log("res",res)
            const { data } = res?.data;
			if(data?.success){
                dispatch(eventsActions.createEventsApiCallSuccess(data));
                if(formData.saveMethod === 'save'){
                    dispatch(eventsActions.updateDraftStatus());
                    dispatch(getEventsApi())
				}else{
                    dispatch(eventsActions.formSubmittedSuccess());
                    dispatch(getEventsApi())
                    ToastSuccess(data?.message)
                }
               
            }else{
                throw "something went Wrong"
            }
        } catch (error) {
            dispatch(eventsActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const getEventByEventId = (eventId) => {
    return async (dispatch,getState) => {
        dispatch(eventsActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "GET",
					url: `/mds/events/get/${eventId}`,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
                
                console.log("data by id ",res)
            if(res?.data?.data?.success){
                const { data } = res?.data; 
                dispatch(eventsActions.getEventByIdSuccess(data?.data));
            }else{
                throw "someThing wrong !";
            }
        } catch (error) {
            dispatch(eventsActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const updateEventsApi = (formData,id) => {
    return async (dispatch,getState) => {
        dispatch(eventsActions.apiCallRequested());
        const token = getState()?.LoginRes?.authResToken;

        try {
            const res = await axios({
					method: "POST",
					url: `/mds/events/update/${id}`,
                    data: formData,
					headers:{
						Authorization:`Bearer ${token}`
					}
				});
           
            console.log(" update eventRes",res)
            const { data , success  , message } = res?.data;
			if(data?.success){
                dispatch(eventsActions.updateEventSuccess(res?.data?.data));
                if(formData.saveMethod === 'save'){
                    dispatch(eventsActions.updateDraftStatus());
                    dispatch(getEventsApi())
				}else{
                    dispatch(eventsActions.formSubmittedSuccess());
                    dispatch(getEventsApi())
                    ToastSuccess(data?.message)
                }
               
            }else{
                throw "something went Wrong"
            }
        } catch (error) {
            dispatch(eventsActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

export const deleteEventApi = (data) => {
    return async (dispatch, getState) => {
		const bodyData = { "itemToDelete": data };
        const token = getState()?.LoginRes?.authResToken;
        dispatch(eventsActions.apiCallRequested());
        try {
            const res = await axios({
                method: "POST",
				data: bodyData,
				url: `/mds/events/delete`, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { data  } = res?.data;
			console.log(data?.success);
            if (data?.success === true) {
                dispatch(eventsActions.deleteOfferSuccess(data?.message));
                dispatch(getEventsApi())
				ToastSuccess(data?.message);
            } else {
				console.log('here 2');
                throw data?.message;
            }
        } catch (error) {
            dispatch(eventsActions.apiCallFailed(error));
            console.log(error)
        }
    }
};

 



export const eventsActions = eventsListSlice.actions;

export default eventsListSlice.reducer;
