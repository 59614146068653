import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ToastSuccess, ToastError } from "../../common/Toast";
import { UserListApi } from "./users";


/*** 
 * createUsser Reducer initial state
 * ***/
 const initialStateUpdateUser = { isLoading: false, isError: "", updateUserRes: null ,changeRoute:false,updateUserSuccess:false};


 const updateUserSlice = createSlice({
     name:"updateUser",
     initialState : initialStateUpdateUser,

     reducers:{
        PostApiCallRequested(state) {
            state.isLoading = true;
            state.changeRoute=false;
            state.isError = "";
            state.updateUserSuccess=false;
        },
        PostApiCallSuccess(state, action) {
            state.isLoading = false;
            state.updateUserRes = action.payload;
            state.changeRoute=true;
            state.updateUserSuccess=true;
        },
        PostApiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
            state.changeRoute=false;
            state.updateUserSuccess=false;
        },
        emptyState(state){
            state.changeRoute=false;
            state.isError = "";
            state.updateUserSuccess=false;
            state.updateUserRes=null
        }
     }
 });


 /***
 * UPDATE USER POST API
 * ***/
export const updateUserApi = (data) => {
    return async (dispatch, getState) => {
        const token = getState()?.LoginRes?.authResToken;
        dispatch(updateUserActions.PostApiCallRequested());
        try {
            const res = await axios({
                method: "POST", url: `/users/update`, data, headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const { error, message } = res?.data;
            if (!error) {
                const { data } = res?.data;
                console.log("res===>",res)
                dispatch(UserListApi())
                dispatch(updateUserActions.PostApiCallSuccess(data?.info));
                ToastSuccess(data?.info)
            } else {
                throw message;
            }
        } catch (error) {
            dispatch(updateUserActions.PostApiCallFailed(error));
            console.log(error)
            ToastError("Oops ! Form not saved. Please review and try again.");
        }
    }
};

export const updateUserActions = updateUserSlice.actions;

export default updateUserSlice.reducer;


