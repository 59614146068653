import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import { ToastError } from "../../common/Toast";
import { getToken } from "../../config";

const iniState = { isLoading: false, isError: null, mdsAnalytics: null};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState: iniState,
    reducers: {
        apiCallRequested(state) {
            state.isLoading = true;
        },
		apiCallFailed(state, action) {
            state.isLoading = false;
            state.isError = action.payload;
        },
        mdsAnalyticsSuccess(state, action) {
            state.isLoading = false;
            state.mdsAnalytics = action.payload;
        },
    },
});

export const getMDSAnalyticsAPI = () => {
    return async (dispatch, getState) => {
        dispatch(analyticsActions.apiCallRequested());
        const selectedMall = getState()?.mallsStore?.selectedMall;
        try {
            const res = await axios({
					method: 'GET',
					url: '/mds/analytics?mid=' + selectedMall,
					headers:{
						Authorization:`Bearer ${getToken()}`
					}
				});
            if(res?.data?.error === false){
                dispatch(analyticsActions.mdsAnalyticsSuccess(res?.data?.data));
            }else if(res?.data?.message){
                ToastError(res.data.message);
            }else{
                ToastError("Unable to connect to API, please try again later!");
                dispatch(analyticsActions.apiCallFailed('Unable to connect to API, please try again later!'));
            }
        } catch (error) {
            ToastError("Unable to connect to API, please try again later!");
            dispatch(analyticsActions.apiCallFailed(error));
            //console.log(error)
        }
    }
};

export const analyticsActions = analyticsSlice.actions;

export default analyticsSlice.reducer;
